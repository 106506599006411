import React from 'react';
import { useQuery } from '@apollo/client';
import DataTable from '@preflighttech/preflight-tables';

import { HOTELS } from './queries';

const columns = [
  {
    key: 'name',
    label: 'Name',
    sort: 'asc',
    content: ({ entry }) => {
      return <a href={`/hotels/${entry.slug}`}>{entry.name}</a>
    }
  },
  {
    key: 'hotelChain',
    label: 'Chain',
    content: ({ entry }) => {
      return <>{entry.hotelChain?.name}</>
    }
  },
  {
    key: 'city',
    label: 'City',
  },
  {
    key: 'countryLabel',
    label: 'Country',
  },
  
];

const Hotels = () => {

  return (
    <div class="container">
      <h3 style={{color: "#A17BD4"}}><i class="fa-solid fa-hotel"></i> Hotels</h3>
      <DataTable.Gql
        query={HOTELS}
        queryName="hotelsWithCount"
        columns={columns}
        htmlTable
        pageLength={25}
        lengthMenu={ [10, 25, 50, 'All'] }
      />
    </div>
  );
};

export default Hotels;

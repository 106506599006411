import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import Container from 'react-bootstrap/Container';

import DataState from '../DataState';
import ValidationErrors from '../ValidationErrors';

import { REGISTER_USER } from './queries';

export const EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Register = () => {
  const [submitting, setSubmitting] = useState();
  const [success, setSuccess] = useState();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const password = useRef({});
  password.current = watch('password', '');

  const [registerUser, { error }] = useMutation(REGISTER_USER, {
    onCompleted: data => {
      setSuccess(true);
      setSubmitting(false);
    },
    onError: () => {
      setSubmitting(false);
    }
  });

  const handleRegister = data => {
    registerUser({ variables: data });
    setSubmitting(true);
  };

  if (success) {
    return (
      <Container>
        Check your email for confirmation instructions
      </Container>
    );
  }

  return (
    <Container>
      <h2>Create Your Account</h2>
      <DataState.Wrapper loading={submitting}>
        {error && <div>{error.message}</div>}

        <form onSubmit={handleSubmit(handleRegister)} className="ts-form">
          Email
          <ValidationErrors name="email" errors={errors} />
          <input
            placeholder="my.email@example.com"
            {...register('email', {
              required: true,
              pattern: { value: EMAIL, message: 'Must be a valid email' }
            })}
          />

          Username
          <ValidationErrors name="username" errors={errors} />
          <input
            placeholder="username"
            {...register('username', { required: true })}
          />

          Display Name
          <ValidationErrors name="displayName" errors={errors} />
          <input
            placeholder="Display Name"
            {...register('displayName', { required: true })}
          />

          Password
          <ValidationErrors name="password" errors={errors} />
          <input
            type="password"
            {...register('password', {
              required: true,
              minLength: { value: 8, message: 'Must be at least 8 characters' },
            })}
          />

          Confirm Password
          <ValidationErrors name="passwordConfirmation" errors={errors} />
          <input
            type="password"
            {...register('passwordConfirmation', {
              required: true,
              validate: value => (
                value === password.current ||
                  'Confirmation does not match password'
              )
            })}
          />

          <input value="Create" type="submit" />
        </form>
      </DataState.Wrapper>
    </Container>

  );
};

export default Register;

import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { setDate } from '../../utils/dateHelpers';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api'
import { useRef, useState } from 'react'
import DataState from '../DataState';

const FlightMap = (props) => {
  const data = props.data;
  const locations = props.locations;
  const queryStatus = props.queryStatus;
  const [map, setMap] = useState(/** @type google.maps.Map */ (null))

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAs-wC7nbdawdnHtHJaRStAD3weHiY2nfU"
  })

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef()

  const center = { lat: 0.0, lng: 0.0 }

  return (
     <DataState.Wrapper data={data} {...queryStatus}>
        <div style={{height: '50vh', width: '100vw'}}>
          <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
              zoomControl: true,
              streetViewControl: false,
              mapTypeControl: true,
              fullscreenControl: true,
            }}
            onLoad={map => {
              const bounds = new window.google.maps.LatLngBounds();
              var path_points = Array();
              var markers = Array();
              var info_windows = Array();
              var idx = 0;

              // Add Markers for Airports
              locations.map(marker => {
                path_points.push(new window.google.maps.LatLng(marker.latitude, marker.longitude));
                var image = {
                  url: marker.image,
                  size: new window.google.maps.Size(30, 52),
                  anchor: new window.google.maps.Point(15, 52)
                };
                
                markers.push(new window.google.maps.Marker({
                  position: path_points[idx],
                  map: map,
                  icon: image
                }));
                
                var pos = new window.google.maps.LatLng(marker.latitude, marker.longitude);
                bounds.extend(pos);

                info_windows.push(new window.google.maps.InfoWindow({
                  content: marker.infoBox
                }));
                window.google.maps.event.addListener(markers[idx], 'click', function() {
                  info_windows[idx].open(map, markers[idx]);
                });
                idx = idx + 1;
              }); 


              map.fitBounds(bounds);

            }}

          >
          </GoogleMap>
        </div>
    </DataState.Wrapper>
  );
};

export default FlightMap;

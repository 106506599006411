import React, { useContext, useEffect, useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import Container from 'react-bootstrap/Container';

import DataState from '../DataState';
import ValidationErrors from '../ValidationErrors';
import ClientContext from '../../contexts/ClientContext';

import { UPDATE_CURRENT_USER } from './queries';
import { EMAIL } from './Register';

const Register = () => {
  const { currentUser, refetchCurrentUser } = useContext(ClientContext);
  const [submitting, setSubmitting] = useState();
  const [success, setSuccess] = useState();
  const { register, handleSubmit, watch, reset, formState: { errors } } =
    useForm();

  const password = useRef({});
  password.current = watch('password');

  const resetForm = () => {
    reset({
      email: currentUser.email,
      username: currentUser.username,
      displayName: currentUser.displayName,
      password: '',
      passwordConfirmation: '',
    });
  };

  const [updateUser, { error }] = useMutation(UPDATE_CURRENT_USER, {
    onCompleted: data => {
      refetchCurrentUser();
      setSubmitting(false);
      resetForm();
    },
    onError: () => {
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (currentUser) {
      resetForm();
    }
  }, [currentUser]);

  const handleRegister = data => {
    updateUser({ variables: data });
    setSubmitting(true);
  };

  return (
    <Container>
      <h2>Update Your Account</h2>
      <DataState.Wrapper loading={submitting}>
        {error && <div>{error.message}</div>}

        <form onSubmit={handleSubmit(handleRegister)} className="ts-form">
          Email
          <ValidationErrors name="email" errors={errors} />
          <input
            placeholder="my.email@example.com"
            {...register('email', {
              required: true,
              pattern: { value: EMAIL, message: 'Must be a valid email' }
            })}
          />

          Username
          <ValidationErrors name="username" errors={errors} />
          <input
            placeholder="username"
            {...register('username', { required: true })}
          />

          Display Name
          <ValidationErrors name="displayName" errors={errors} />
          <input
            placeholder="Display Name"
            {...register('displayName', { required: true })}
          />

          Change Password
          <ValidationErrors name="password" errors={errors} />
          <input type="password" {...register('password')} />

          Confirm Change Password
          <ValidationErrors name="passwordConfirmation" errors={errors} />
          <input
            type="password"
            {...register('passwordConfirmation', {
              validate: value => (
                value === password.current ||
                  'Confirmation does not match password'
              )
            })}
          />

          <input value="Update Account" type="submit" />
        </form>
      </DataState.Wrapper>
    </Container>

  );
};

export default Register;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import Container from 'react-bootstrap/Container';

import DataState from '../DataState';
import ValidationErrors from '../ValidationErrors';
import ClientContext from '../../contexts/ClientContext';

import { SEND_RESET_PASSWORD, UPDATE_PASSWORD } from './queries';

const UpdatePassword = ({ token }) => {
  const [submitting, setSubmitting] = useState();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const { updateCredentials } = useContext(ClientContext);

  const password = useRef({});
  password.current = watch('password', '');

  const [updatePassword, { error }] = useMutation(UPDATE_PASSWORD, {
    onCompleted: data => {
      updateCredentials(data.userUpdatePasswordWithToken.credentials);
      navigate('/profile/login');
    }
  });

  if (error) {
    return (
      <span>{error.message}</span>
    );
  }

  const handleUpdate = data => {
    updatePassword(
      { variables: {...data, resetPasswordToken: token } }
    );
  };

  return (
    <Container>
      <h2>Reset Password</h2>
      <DataState.Wrapper loading={submitting}>
        <form onSubmit={handleSubmit(handleUpdate)} className="ts-form">
          New Password
          <ValidationErrors name="password" errors={errors} />
          <input
            type="password"
            {...register('password', {
              required: true,
              minLength: { value: 8, message: 'Must be at least 8 characters' },
            })}
          />

          Confirm Password
          <ValidationErrors name="passwordConfirmation" errors={errors} />
          <input
            type="password"
            {...register('passwordConfirmation', {
              required: true,
              validate: value => (
                value === password.current ||
                  'Confirmation does not match password'
              )
            })}
          />

          <input value="Update Password" type="submit" />
        </form>
      </DataState.Wrapper>
    </Container>
  );
}

const ResetPassword = () => {
  const [submitting, setSubmitting] = useState();
  const [success, setSuccess] = useState();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('reset_password_token');

  const [sendResetPassword] = useMutation(SEND_RESET_PASSWORD, {
    onCompleted: data => setSuccess(true)
  });

  const handleSendReset = data => {
    sendResetPassword(
      { variables: { email: data.email, redirectUrl: window.location.href } }
    );
  };

  if (token && token.length > 0) {
    return (
      <UpdatePassword token={token} />
    );
  }

  if (success) {
    return (
      <Container>
        Check your email for instructions
      </Container>
    );
  }

  return (
    <Container>
      <h2>Reset Password</h2>
      <DataState.Wrapper loading={submitting}>
        <form onSubmit={handleSubmit(handleSendReset)} className="ts-form">
          <input
            placeholder="Email"
            {...register('email')}
          />
          <input value="Request Reset Token" type="submit" />
        </form>
      </DataState.Wrapper>
    </Container>
  );
};

export default ResetPassword;

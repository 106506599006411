import { gql } from '@apollo/client';

import {
  WITH_COUNT_VARIABLES,
  WITH_COUNT_ARGUMENTS
} from '../../queries/withCount';

export const HOTEL_CORE_FIELDS = gql`
  fragment HotelCoreFields on Hotel {
    id
    entityId
    name
    street
    city
    state
    postalCode
    country
    countryLabel
    latitude
    longitude
    status
    url
    roomCount
    phoneNumber
    marker
    logoUri
    slug
  }
`;

export const HOTEL_FIELDS = gql`
  ${HOTEL_CORE_FIELDS}
  fragment HotelFields on Hotel {
    ...HotelCoreFields
    hotelChain { id, name }
    infoBox
  }
`;

export const HOTELS = gql`
  ${HOTEL_FIELDS}
  query HotelsWithCount(
    ${WITH_COUNT_VARIABLES}
  ) {
    hotelsWithCount(
      ${WITH_COUNT_ARGUMENTS}
    ) {
      entries {
        ...HotelFields
      }
      count
    }
  }
`;

export const HOTEL = gql`
  ${HOTEL_FIELDS}
  query Hotel($slug: String!) {
    hotelBySlug(slug: $slug) {
      ...HotelFields
    }
  }
`;

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';

import DataState from '../DataState';

import Form from './form';
import { CREATE_USER_FILE } from './queries';
import useUserFilePath from './path';

const NewUserFile = ({ resourceType }) => {
  const { resourceId } = useParams();
  const userFilePath = useUserFilePath();

  const [submitting, setSubmitting] = useState();
  const { register, control, handleSubmit, watch, formState: { errors } } =
    useForm({
      defaultValues: { resourceType, resourceId, type: null, visibility: 'Public' }
    });

  const [createUserFile, { error }] = useMutation(CREATE_USER_FILE, {
    onCompleted: () => {
      window.location.href = userFilePath({ resourceType, resourceId });
    }
  });

  const onSubmit = data => {
    createUserFile({ variables: data });
    setSubmitting(true);
  };

  return (
    <Container>
      <h2>Add A File</h2>
      <center>
        <div className="form-container">
          <DataState.Wrapper loading={submitting}>
            <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
              <Form
                register={register}
                control={control}
                resourceType={resourceType}
                errors={errors}
                watch={watch}
              />

              <input value="Add File" type="submit" id="submit-button" />
            </form>
          </DataState.Wrapper>
        </div>
      </center>
    </Container>
  );
};

export default NewUserFile;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';

import DataState from '../../DataState';

import Form from './form';
import { LOYALTY_PROGRAM, UPDATE_LOYALTY_PROGRAM } from './queries';

const EditLoyaltyProgram = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState();
  const [userErrors, setUserErrors] = useState();
  const { register, control, reset, handleSubmit } = useForm();

  const { data } = useQuery(LOYALTY_PROGRAM, { variables: { id } });

  const [updateLoyaltyProgram] = useMutation(UPDATE_LOYALTY_PROGRAM, {
    onCompleted: data => {
      if (data.updateLoyaltyProgram.errors.length > 0) {
        setUserErrors(data.updateLoyaltyProgram.errors);
        setSubmitting(false);
      } else {
        navigate('/admin/loyaltyPrograms');
      }
    }
  });

  useEffect(() => {
    if (data?.loyaltyProgram) {
      reset({
        id: data.loyaltyProgram.id,
        programType: data.loyaltyProgram.programType,
        name: data.loyaltyProgram.name,
        companyName: data.loyaltyProgram.companyName,
        url: data.loyaltyProgram.url,
      });
    }
  }, [data]);

  const onSubmit = data => {
    updateLoyaltyProgram({ variables: data });
    setSubmitting(true);
  };

  return (
    <Container>
      <h2>Update LoyaltyProgram: {data?.loyaltyProgram.name}</h2>
      <center>
        <div className="form-container">
          <DataState.Wrapper loading={!data || submitting}>
            <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
              <Form
                register={register}
                control={control}
                userErrors={userErrors}
              />

              <input value="Update LoyaltyProgram" type="submit" id="submit-button" />
            </form>
          </DataState.Wrapper>
        </div>
      </center>
    </Container>
  );
};

export default EditLoyaltyProgram;

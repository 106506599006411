import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import DataState from '../../DataState';
import ClientContext from '../../../contexts/ClientContext';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

import Form from './form';
import { CREATE_LOYALTY_PROGRAM } from './queries';

const NewFlight = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState();
  const [userErrors, setUserErrors] = useState(null);
  const { register, handleSubmit, control } = useForm();
  const { currentUser } = useContext(ClientContext);

  const [createLoyaltyProgram] = useMutation(CREATE_LOYALTY_PROGRAM, {
    onCompleted: data => {
      console.log(data);
      if (data.createLoyaltyProgram.loyaltyProgram) {
        navigate(`/admin/loyaltyPrograms`);
      } else {
        setUserErrors(data.createLoyaltyProgram.userErrors);
        setSubmitting(false);
      }
    }
  });

  const onSubmit = data => {

    createLoyaltyProgram({ variables: data });
    setSubmitting(true);
  };

  if (!currentUser) {
    return null
  }
  if (currentUser?.admin !== true) {
    navigate('/');
    return false;
  }

  return (
    <Container>
      { userErrors != null && (
        <>
          <Alert key="danger" variant="danger">
          {userErrors[0].message}
        </Alert>
        </>
      )}
      <h2>Add A New Loyalty Program</h2>
      <center>
        <div className="form-container">
          <DataState.Wrapper loading={submitting}>
            <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
              <Form
                register={register}
                control={control}
                userErrors={userErrors}
              />

              <input value="Add Loyalty Program" type="submit" id="submit-button"  />
            </form>
          </DataState.Wrapper>
        </div>
      </center>
    </Container>
  );
};

export default NewFlight;

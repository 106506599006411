import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useSearchParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { setDate } from '../../../utils/dateHelpers';
import StaysTable from './table';
import Map from '../../maps/multiLocation';
import StaysStats from './stats';
import DataState from '../../DataState';

import { STAYS } from './queries';
import Filter from './Filter';

const Flights = () => {
  const { username, ...filterParams } = useParams();
  const [searchParams] = useSearchParams();

  searchParams.forEach((value, key) => {
    if (!filterParams[key]) {
      filterParams[key] = value;
    }
  });

  const [filters, setFilters] = useState(filterParams);

  const queryFilters = {
    airline: filters.airline,
    flightNumber: filters.flightNumber,
    operator: filters.operator,
    airport: filters.airport,
    pairAirport: filters.pairAirport,
    aircraft: filters.aircraft,
    tail: filters.tail,
    year: (filters.year ? parseInt(filters.year) : null),
  }

  const { data, ...queryStatus } = useQuery(STAYS, {
    variables: {
      username,
      filters: queryFilters,
      sort: 'checkInOn',
      sortDirection: 'asc',
    }
  });

  return (
    <>
      <DataState.Wrapper data={data} {...queryStatus}>
        <h1>{data?.userByUsername?.displayName}'s Stays</h1>
        { data?.stays.length == 0 &&
          <>
            <Row>
              <Col>
                {data?.userByUsername?.displayName} has no stays.
              </Col>
            </Row>
          </>
        }
        { data?.stays.length > 0 &&
          <Map 
            data={data} 
            locations={data.staysForMap.markers}
            queryStatus={queryStatus} 
          />
        }
        
        { data?.stays.length > 0 &&
          <>
            <StaysStats data={data} queryStatus={queryStatus} username={username} />
            <StaysTable data={data} queryStatus={queryStatus} username={username} />
          </>
        }
      </DataState.Wrapper>
    </>
  );
};

export default Flights;

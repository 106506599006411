const config = {
  api: {
    baseUri: process.env.REACT_APP_API_URI,
    uri: process.env.REACT_APP_API_URI + '/graphql',
  },
  pti: {
    token: process.env.REACT_APP_PTI_TOKEN,
    issueUrl: 'https://preflighttech.com/api/1/issues'
  },
  app: {
    name: 'ts-react',
    version: 20221220,
  }
};

export default config;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';

import DataState from '../../DataState';

import Form from './form';
import { CREATE_HOTEL } from './queries';

const NewHotel = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState();
  const [userErrors, setUserErrors] = useState();
  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      status: 'Open',
    }
  });

  const [createHotel, { error }] = useMutation(CREATE_HOTEL, {
    onCompleted: data => {
      if (data.createHotel.hotel) {
        navigate('/admin/hotels');
      } else {
        setUserErrors(data.createHotel.errors);
        setSubmitting(false);
      }
    }
  });

  const onSubmit = data => {
    if (data.roomCount) {
      data.roomCount = parseInt(data.roomCount);
    } else {
      data.roomCount = null;
    }

    createHotel({ variables: data });
    setSubmitting(true);
  };

  return (
    <Container>
      <h2>Add A New Hotel</h2>
      <center>
        <div className="form-container">
          <DataState.Wrapper loading={submitting}>
            <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
              <Form
                register={register}
                control={control}
                userErrors={userErrors}
              />

              <input value="Add Hotel" type="submit" id="submit-button" />
            </form>
          </DataState.Wrapper>
        </div>
      </center>
    </Container>
  );
};

export default NewHotel;

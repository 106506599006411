import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import DataState from '../../DataState';

import Form from './form';
import AirlineAirportForm from './airportForm';
import { AIRLINE_WITH_AIRPORTS, UPDATE_AIRLINE, CREATE_AIRLINE_AIRPORT } from './queries';

const AirlineAirportDiv = ({airlineAirport}) => {
  const ap = airlineAirport.airport;
  return (
    <div style={{width: "100%", border: "1px solid black", borderRadius: "10px", padding: "10px", marginBottom: "5px"}}>
      {airlineAirport.hub === true &&
        <>[HUB]&nbsp;</>
      }
      {airlineAirport.focusCity === true &&
        <>[FOCUS CITY]&nbsp;</>
      }
      {ap.name} ({ap.icao} / {ap.iata})&nbsp;
      [{ap.city}, {ap.country}]
    </div>
  )
}

const EditAirline = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState();
  const [userErrors, setUserErrors] = useState();
  const [show, setShow] = useState(false);
  const { register, control, reset, handleSubmit } = useForm();

  const { register: airlineRegister, control: airlineControl, reset: airlineReset, handleSubmit: airlineHandleSubmit } = useForm();

  const { data, refetch } = useQuery(AIRLINE_WITH_AIRPORTS, { variables: { id } });

  const [updateAirline, { error }] = useMutation(UPDATE_AIRLINE, {
    onCompleted: data => {
      if (data.updateAirline.errors.length > 0) {
        setUserErrors(data.updateAirline.errors);
        setSubmitting(false);
      } else {
        navigate('/admin/airlines');
      }
    }
  });

  const [createAirlineAirport] = useMutation(CREATE_AIRLINE_AIRPORT, {
    onCompleted: data => {
      if (data.createAirlineAirport.errors.length > 0) {
        setUserErrors(data.createAirlineAirport.errors);
        setSubmitting(false);
      } else {
        refetch();
      }
    }
  });

  useEffect(() => {
    if (data?.airline) {
      reset({
        id: data.airline.id,
        name: data.airline.name,
        icao: data.airline.icao,
        iata: data.airline.iata,
        callsign: data.airline.callsign,
        country: data.airline.country,
        about: data.airline.about,
        loyaltyProgramId: data.airline.loyaltyProgram?.id,
        url: data.airline.url,
      });
    }
  }, [data]);

  const onSubmit = data => {
    updateAirline({ variables: data });
    setSubmitting(true);
  };

  const onAirportSubmit = data => {
    setSubmitting(true);
    if (data.hub === "true"){
      data.hub = true
    } else {
      data.hub = false
    }
    if (data.focusCity === "true"){
      data.focusCity = true
    } else {
      data.focusCity = false
    }

    createAirlineAirport({ variables: data });
    setSubmitting(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Container>
        <h2>Update Airline: {data?.airline.name}</h2>
        <DataState.Wrapper loading={!data || submitting}>
          <Row>
            <Col className="form-container">
              <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
                <Form
                  register={register}
                  control={control}
                  userErrors={userErrors}
                />

                <input value="Update Airline" type="submit" id="submit-button" />
              </form>
              
            </Col>
            <Col style={{backgroundColor: "#edf7ff"}}>
              <h3>Airports Served</h3>
              <Button variant="primary" onClick={handleShow}>
                Add Airports Served
              </Button>
              {
                data?.airline?.airlineAirports?.map(airport => {
                  return(
                    <AirlineAirportDiv
                      airlineAirport={airport}
                    />
                  )
                })
              }
            </Col>
          </Row>
        

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add an Airport to {data?.airline.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={airlineHandleSubmit(onAirportSubmit)} className="ts-form">
                <AirlineAirportForm
                  register={airlineRegister}
                  control={airlineControl}
                  userErrors={userErrors}
                  airportsData={data?.airports}
                  airlineId={id}
                />

                <input value="Add Airport" type="submit" id="submit-button" />
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </DataState.Wrapper>
      </Container>
    </>
  );
};

export default EditAirline;

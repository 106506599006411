import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import Container from 'react-bootstrap/Container';

import DataState from '../DataState';
import ClientContext from '../../contexts/ClientContext';

import { USER_LOGIN, USER_LOGOUT } from './queries';

const Login = () => {
  const [submitting, setSubmitting] = useState();
  const { credentials, updateCredentials } = useContext(ClientContext);
  const { register, handleSubmit, reset } = useForm();
  const navigate = useNavigate();

  const [login, { error }] = useMutation(USER_LOGIN, {
    onCompleted: data => {
      updateCredentials(data.userLogin.credentials);
      reset({});
      setSubmitting(false);
      navigate('/');
    },
    onError: () => {
      setSubmitting(false);
    }
  });

  const [logout] = useMutation(USER_LOGOUT, {
    onCompleted: () => updateCredentials({}),
    onError: error => {
      console.log('Logout Error', error);
      updateCredentials({});
    }
  });

  const handleLogin = data => {
    login({ variables: data });
    setSubmitting(true);
  };

  if (credentials?.accessToken) {
    return (
      <Container>
        <div className="ts-form">
          <input
            value="Logout"
            type="submit"
            className="submit-button"
            onClick={logout}
          />
        </div>
      </Container>
    );
  }

  const showCreate = false;
  return (
    <Container>
      <h2>Login</h2>
      <DataState.Wrapper loading={submitting}>
        {showCreate === true && (
          <>
            <Link to="/profile/register">Create an Account</Link>
            {' | '}
          </>
        )}
        <Link to="/profile/reset-password">Reset Password</Link>

        {error && <div>{error.message}</div>}

        <form onSubmit={handleSubmit(handleLogin)} className="ts-form">
          <input
            placeholder="Email"
            {...register('email')}
          />
          <input
            type="password"
            placeholder="Password"
            {...register('password')}
          />
          <input value="Login" type="submit" className="submit-button" />
        </form>
      </DataState.Wrapper>
    </Container>
  );
};

export default Login;

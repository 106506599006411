import React from 'react';

import {
  Routes as Switch,
  Route
} from 'react-router-dom';

import LoggedInRoute from './LoggedInRoute';
import AdminRoutes from './AdminRoutes';

import Home from '../components/Home';
import PrivacyPolicy from '../components/Home/privacy';
import Login from '../components/Profile/Login';
import Register from '../components/Profile/Register';
import ConfirmUser from '../components/Profile/Confirm';
import EditUser from '../components/Profile/edit';
import ResetPassword from '../components/Profile/ResetPassword';
import Premium from '../components/Profile/Premium';

import Airports from '../components/Airports';
import Airport from '../components/Airports/show'
import Airlines from '../components/Airlines';
import Airline from '../components/Airlines/show'
import Hotels from '../components/Hotels';
import Hotel from '../components/Hotels/show';

import Flights from '../components/Journeys/Flights';
import Flight from '../components/Journeys/Flights/show';
import NewFlight from '../components/Journeys/Flights/new';
import UploadFlights from '../components/Journeys/Flights/upload';
import EditFlight from '../components/Journeys/Flights/edit';
import TroubleTicket from '../components/troubleTicket/Ticket';
import Donations from '../components/donations';

import Stays from '../components/Journeys/Stays';
import Stay from '../components/Journeys/Stays/show';
import NewStay from '../components/Journeys/Stays/new';
import EditStay from '../components/Journeys/Stays/edit';

import NewUserFile from '../components/UserFiles/new';
import EditUserFile from '../components/UserFiles/edit';

import AdminLoyaltyPrograms from '../components/Admin/LoyaltyPrograms';
import NewAdminLoyaltyPrograms from '../components/Admin/LoyaltyPrograms/new';
import EditAdminLoyaltyPrograms from '../components/Admin/LoyaltyPrograms/edit';

import AdminAirlines from '../components/Admin/Airlines';
import NewAdminAirlines from '../components/Admin/Airlines/new';
import EditAdminAirlines from '../components/Admin/Airlines/edit';

import AdminHotelChains from '../components/Admin/HotelChains';
import NewAdminHotelChain from '../components/Admin/HotelChains/new';
import EditAdminHotelChain from '../components/Admin/HotelChains/edit';

import AdminHotels from '../components/Admin/Hotels';
import NewAdminHotel from '../components/Admin/Hotels/new';
import EditAdminHotel from '../components/Admin/Hotels/edit';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" element={<Donations />} />
      <Route exact path="/support-travel-social" element={<Donations />} />
      <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route exact path="/feedback" element={<TroubleTicket />} />
      <Route exact path="/profile/login" element={<Login />} />
      <Route exact path="/profile/register" element={<Register />} />
      <Route exact path="/profile/confirm" element={<ConfirmUser />} />
      <Route exact path="/profile/edit" element={<EditUser />} />
      <Route exact path="/profile/reset-password" element={<ResetPassword />} />
      <Route exact path="/profile/premium/:source" element={<Premium />} />
      <Route exact path="/airports" element={<Airports />} />
      <Route exact path="/airports/:slug" element={<Airport />} />
      <Route exact path="/airlines" element={<Airlines />} />
      <Route exact path="/airlines/:slug" element={<Airline />} />
      <Route exact path="/hotels" element={<Hotels />} />
      <Route exact path="/hotels/:slug" element={<Hotel />} />

      <Route exact path="/journeys/:username/flights">
        <Route exact index element={<Flights />} />
        <Route exact path=":id" element={<Flight />} />
        <Route
          exact
          path=":id/edit"
          element={<LoggedInRoute><EditFlight /></LoggedInRoute>}
        />
        <Route
          exact
          path=":resourceId/files/new"
          element={
            <LoggedInRoute>
              <NewUserFile resourceType="UserFlight" />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path=":resourceId/files/:id/edit"
          element={
            <LoggedInRoute>
              <EditUserFile resourceType="UserFlight" />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path="new"
          element={<LoggedInRoute><NewFlight /></LoggedInRoute>}
        />
        <Route
          exact
          path="upload"
          element={<LoggedInRoute><UploadFlights /></LoggedInRoute>}
        />
        <Route exact path="airline/:airline" element={<Flights />} />
        <Route
          exact
          path="airline/:airline/flight-number/:flightNumber"
          element={<Flights />}
        />
        <Route exact path="operator/:operator" element={<Flights />} />
        <Route exact path="airport/:airport" element={<Flights />} />
        <Route exact path="aircraft/:aircraft" element={<Flights />} />
        <Route
          exact
          path="city-pair/:airport/:pairAirport"
          element={<Flights />}
        />
        <Route exact path="tail/:tail" element={<Flights />} />
        <Route exact path="year/:year" element={<Flights />} />
      </Route>

      <Route exact path="/journeys/:username/stays">
        <Route exact index element={<Stays />} />
        <Route exact path=":id" element={<Stay />} />
        <Route exact path="new" element={<NewStay />} />
        <Route
          exact
          path=":id/edit"
          element={<LoggedInRoute><EditStay /></LoggedInRoute>}
        />
        <Route
          exact
          path=":resourceId/files/new"
          element={
            <LoggedInRoute>
              <NewUserFile resourceType="Stay" />
            </LoggedInRoute>
          }
        />
        <Route
          exact
          path=":resourceId/files/:id/edit"
          element={
            <LoggedInRoute>
              <EditUserFile resourceType="Stay" />
            </LoggedInRoute>
          }
        />
      </Route>

      <Route path="/admin" element={<AdminRoutes />}>
        <Route exact path="loyaltyPrograms" element={<AdminLoyaltyPrograms />} />
        <Route
          exact
          path="loyaltyPrograms/new"
          element={<NewAdminLoyaltyPrograms />}
        />
        <Route
          exact
          path="loyaltyPrograms/:id/edit"
          element={<EditAdminLoyaltyPrograms />}
        />

        <Route exact path="airlines" element={<AdminAirlines />} />
        <Route exact path="airlines/new" element={<NewAdminAirlines />} />
        <Route exact path="airlines/:id/edit" element={<EditAdminAirlines />} />

        <Route exact path="hotelChains" element={<AdminHotelChains />} />
        <Route exact path="hotelChains/new" element={<NewAdminHotelChain />} />
        <Route
          exact
          path="hotelChains/:id/edit"
          element={<EditAdminHotelChain />}
        />

        <Route exact path="hotels" element={<AdminHotels />} />
        <Route exact path="hotels/new" element={<NewAdminHotel />} />
        <Route exact path="hotels/:id/edit" element={<EditAdminHotel />} />
      </Route>
    </Switch>
  );
};

export default Routes;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';

import DataState from '../../DataState';

import Form from './form';
import { HOTEL, UPDATE_HOTEL } from './queries';

const EditHotel = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState();
  const [userErrors, setUserErrors] = useState();
  const { register, control, reset, handleSubmit } = useForm();

  const { data } = useQuery(HOTEL, { variables: { id } });

  const [updateHotel] = useMutation(UPDATE_HOTEL, {
    onCompleted: data => {
      if (data.updateHotel.errors.length > 0) {
        setUserErrors(data.updateHotel.errors);
        setSubmitting(false);
      } else {
        navigate('/admin/hotels');
      }
    }
  });

  useEffect(() => {
    if (data?.hotel) {
      reset({
        id: data.hotel.id,
        hotelChainId: data.hotel.hotelChain?.id,
        name: data.hotel.name,
        street: data.hotel.street,
        city: data.hotel.city,
        state: data.hotel.state,
        postalCode: data.hotel.postalCode,
        country: data.hotel.country,
        status: data.hotel.status,
        url: data.hotel.url,
        roomCount: data.hotel.roomCount,
        phoneNumber: data.hotel.phoneNumber,
      });
    }
  }, [data]);

  const onSubmit = data => {
    if (data.roomCount) {
      data.roomCount = parseInt(data.roomCount);
    } else {
      data.roomCount = null;
    }

    updateHotel({ variables: data });
    setSubmitting(true);
  };

  return (
    <Container>
      <h2>Update Hotel: {data?.hotel.name}</h2>
      <center>
        <div className="form-container">
          <DataState.Wrapper loading={!data || submitting}>
            <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
              <Form
                register={register}
                control={control}
                userErrors={userErrors}
              />

              <input value="Update Hotel" type="submit" id="submit-button" />
            </form>
          </DataState.Wrapper>
        </div>
      </center>
    </Container>
  );
};

export default EditHotel;

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { CustomPopper, useStyles } from '../../Journeys/Flights/editForm';
import UserErrors from '../../../elements/UserErrors';
import HotelChainSelect from '../HotelChains/Select';

const STATUS_OPTIONS = [
  'Under Construction',
  'Open',
  'Temporarily Closed',
  'Permanently Closed',
];

const FORMATTED_STATUS_OPTIONS = STATUS_OPTIONS.map(value => (
  { value, label: value }
));

const HotelForm = ({ register, control, userErrors }) => {
  const classes = useStyles();

  const selectedOption = value => {
    if (value) {
      return FORMATTED_STATUS_OPTIONS.find(option => value === option.value);
    } else {
      return null;
    }
  }

  return (
    <>
      {userErrors && <UserErrors userErrors={userErrors} />}

      <Row>
        <Col>
          Name
          <input
            type="text"
            {...register('name')}
          />

          Hotel Chain
          <HotelChainSelect control={control} name="hotelChainId" />

          Street
          <input
            type="text"
            {...register('street')}
          />

          City
          <input
            type="text"
            {...register('city')}
          />

          State
          <input
            type="text"
            {...register('state')}
          />

          Postal Code
          <input
            type="text"
            {...register('postalCode')}
          />

          Country (2-letter code)
          <input
            type="text"
            maxlength="2"
            {...register('country')}
          />

          Status
          <Controller
            control={control}
            name="status"
            render={({ field }) => (
              <Autocomplete
                disablePortal
                className={classes.filter}
                options={FORMATTED_STATUS_OPTIONS}
                sx={{ width: "100%", border: "2px solid black"}}
                style={{border: "2px solid black"}}
                isOptionEqualToValue={(option, selected) => (
                  option.value === selected?.value
                )}
                onChange={(e, selected) => (
                  field.onChange(selected?.value || null)
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    className={classes.textfield}
                  />
                )}
                value={selectedOption(field.value)}
              />
            )}
          />

          Room Count
          <input
            type="number"
            {...register('roomCount')}
          />

          Phone Number
          <input
            type="text"
            {...register('phoneNumber')}
          />

          URL
          <input
            type="text"
            {...register('url')}
          />

          Logo
          <input
            type="file"
            {...register('approvedLogo')}
          />

          Map Marker
          <input
            type="file"
            {...register('marker')}
          />
        </Col>
      </Row>
    </>
  );
};

export default HotelForm;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import Detail from './Detail';
import { CREATE_REVIEW, UPDATE_REVIEW } from './queries';

const Stars = styled.h4`
`;

const DetailLink = styled.p`
  margin: 0;
  margin-top: 6px;
  cursor: pointer;
`;

const STARS = 5;

const Review = ({ entity, experience, review: initialReview }) => {
  const [showDetail, setShowDetail] = useState();
  const [hover, setHover] = useState(0);

  if (!initialReview) {
    initialReview = experience.reviews.find(review => (
      entity.__typename === review.entity.__typename &&
        entity.id === review.entity.id
    ));
  }

  if (!initialReview) {
    initialReview = {
      entityId: entity.entityId,
      experienceId: experience.experienceId,
      rating: 0
    }
  }

  const [review, setReview] = useState(initialReview);
  const { rating } = review;

  const [createReview] = useMutation(CREATE_REVIEW, {
    onCompleted: data => {
      setReview(data.createReview.review);
    }
  });

  const [updateReview] = useMutation(UPDATE_REVIEW, {
    onCompleted: data => {
      setReview(data.updateReview.review);
    }
  });

  const setRating = value => {
    if (review.id) {
      updateReview({
        variables: {
          id: review.id,
          rating: value,
        },
      });
    } else {
      createReview({
        variables: {
          rating: value,
          entityId: review.entityId,
          experienceId: review.experienceId,
        },
      });
    }
  };

  const starClass = starIndex => {
    if (starIndex < hover) {
      return 'fa-duotone';
    } else if (starIndex < rating) {
      return 'fa-solid';
    } else {
      return 'fa-regular';
    }
  }

  console.log(hover);

  return (
    <>
      <Stars>
        {[...Array(STARS)].map((_, starIndex) => (
          <i
            key={starIndex}
            onClick={() => { setRating(starIndex + 1); setHover(0); }}
            className={`${starClass(starIndex)} fa-star`}
            style={{marginRight: 4}}
            onMouseEnter={() => setHover(starIndex + 1)}
            onMouseOut={() => setHover(0)}
          ></i>
        ))}
      </Stars>
      {rating > 0 && (
        <DetailLink onClick={() => setShowDetail(true)}>
          {review.body ? 'Edit detailed review' : 'Add a more detailed review'}
        </DetailLink>
      )}
      {showDetail && (
        <Detail
          review={review}
          handleHide={() => setShowDetail(false)}
          updateReview={updateReview}
        />
      )}
    </>
  );
};

export default Review;

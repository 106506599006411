import React, { useCallback, useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import { DateTime } from 'luxon';

import ClientContext from '../../contexts/ClientContext';
import { LargeButton, TextButton } from '../../styles';

import {
  SETUP_CHECKOUT_SESSION,
  CHANGE_SUBSCRIPTION_TYPE,
  CANCEL_SUBSCRIPTION,
  REINSTATE_SUBSCRIPTION,
  RESUME_UPLOAD_PROCESSING,
} from './queries';

const ANNUAL_UPGRADE_CONFIRMATION = `
  Are you sure you want to upgrade to an annual plan?
  Your card on file will be charged.
`;

const Premium = () => {
  const { currentUser, refetchCurrentUser } = useContext(ClientContext);
  const { source } = useParams();
  const [response, setResponse] = useState();

  const [setupCheckoutSession] = useMutation(SETUP_CHECKOUT_SESSION, {
    onCompleted: data => {
      const { url } = data.setupCheckoutSession
      window.location.href = url;
    }
  });

  const [changeSubscriptionType] = useMutation(CHANGE_SUBSCRIPTION_TYPE, {
    onCompleted: () => {
      setTimeout(refetchCurrentUser, 1000);
      setTimeout(refetchCurrentUser, 4000);
    }
  });

  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION, {
    onCompleted: refetchCurrentUser
  });

  const [reinstateSubscription] = useMutation(REINSTATE_SUBSCRIPTION, {
    onCompleted: refetchCurrentUser
  });

  const [resumeUploadProcessing] = useMutation(RESUME_UPLOAD_PROCESSING, {
    onCompleted: data => {
      setResponse(data.resumeUploadProcessing);
    }
  });

  useEffect(refetchCurrentUser, []);

  const startCheckout = useCallback(pricing => {
    setupCheckoutSession({ variables: { pricing } });
  });

  const confirmUpgradeToAnnual = useCallback(() => {
    if (window.confirm(ANNUAL_UPGRADE_CONFIRMATION)) {
      changeSubscriptionType({ variables: { type: 'annual' } });
    }
  });

  const confirmCancel = useCallback(() => {
    if (window.confirm('Are you sure you want to cancel?')) {
      cancelSubscription();
    }
  });

  const confirmReinstate = useCallback(() => {
    if (window.confirm('Are you sure you want to restart your subscription?')) {
      reinstateSubscription();
    }
  });

  if (response) {
    return (
      <Container>
        <Alert variant="success">
          {response.message}
        </Alert>
      </Container>
    );
  }

  let message;

  if ('upload-flights' === source) {
    message = 'A premium account is required to update flights with the ' +
      'actual flight path, departure and arrival times, tail numbers, and ' +
      'more! We offer monthly and annual plans.';
  }

  if ('subscribed' === source) {
    message = 'Thank you for subscribing! You can now enjoy premium features.'
  }

  let subscriptionStatus = (
    <>
      <LargeButton onClick={() => startCheckout('annual')}>
        Start an Annual Plan - $100/year
      </LargeButton>

      <LargeButton onClick={() => startCheckout('monthly')}>
        Start an Monthly Plan - $10/month
      </LargeButton>

      {'upload-flights' === source && (
        <TextButton onClick={resumeUploadProcessing}>
          Process my file without updating any data.
        </TextButton>
      )}
    </>
  );

  if (!currentUser) {
    subscriptionStatus = null;
  } else if (currentUser.activeSubscription?.canceledAt) {
    subscriptionStatus = (
      <>
        <p>
          Your {currentUser.activeSubscription.type} Premium subscription has
          been canceled. You may continue using premium services
          until {currentUser.activeSubscription.expiresOn}.
        </p>

        <LargeButton onClick={confirmReinstate}>
          Reinstate subscription
        </LargeButton>
      </>
    );
  } else if (currentUser.activeSubscription) {
    subscriptionStatus = (
      <>
        <p>
          Your {currentUser.activeSubscription.type} Premium subscription is
          active and next renews {currentUser.activeSubscription.expiresOn}.
        </p>

        {'monthly' === currentUser.activeSubscription.type && (
          <LargeButton onClick={confirmUpgradeToAnnual}>
            Upgrade to Annual Subscription
          </LargeButton>
        )}

        <LargeButton onClick={confirmCancel}>
          Cancel Subscription
        </LargeButton>
      </>
    );
  }

  return (
    <Container>
      <h2>Premium Access</h2>
      {message && (
        <Alert variant="info">
          {message}
        </Alert>
      )}
      {subscriptionStatus}
    </Container>
  );
};

export default Premium;

import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import DataState from '../../DataState';
import ClientContext from '../../../contexts/ClientContext';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

import { booleanStringsToValues } from '../../../utils/stringHelpers';

import EditForm from './editForm';
import { USER_FLIGHT_FOR_EDIT, UPDATE_USER_FLIGHT } from './queries';

const EditFlight = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState();
  const [userErrors, setUserErrors] = useState(null);
  const { currentUser } = useContext(ClientContext);
  const { id } = useParams();

  const { loading: userFlightLoading, error: userFlightError, data: userFlightData } =
    useQuery(USER_FLIGHT_FOR_EDIT, { variables: { id } } );

  const [updateUserFlight, { error }] = useMutation(UPDATE_USER_FLIGHT, {
    onCompleted: data => {
        navigate(`/journeys/${currentUser.username}/flights/${data.updateUserFlight.userFlight.id}`);
    }
  });

  if (userFlightLoading && !userFlightData) return <DataState.Loading />;
  if (userFlightError) return <DataState.Error error={userFlightError} />;

  const userFlight = userFlightData.userFlight

  let cannotLookupReason = null;
  switch (location.state?.cannotLookupReason) {
    case 'no_subscription':
      cannotLookupReason = (
        <>
          To automatically update details for flights before 14 days ago,{' '}
          <Link to="/profile/premium/account">
            please consider getting a premium subscription
          </Link>
        </>
      );
      break;
    case 'before_min_lookup_date':
      cannotLookupReason = (
        'We are unable to get flight details for flights before Jan 1, 2011.'
      )
      break;
    case 'exceeds_monthly_threshold':
      cannotLookupReason = (
        'We did not get flight details because you have exceeded your ' +
        'monthly lookup limit.'
      );
      break;
    default:
      cannotLookupReason = null;
  }

  const onSubmit = data => {
    data.departureAt = DateTime.fromISO(data.departureAt).toISO();
    data.arrivalAt = DateTime.fromISO(data.arrivalAt).toISO();
    data.bagCount = '' === data.bagCount ? null : parseInt(data.bagCount);

    if (data.flightNumber) {
      data.flightNumber = parseInt(data.flightNumber);
    } else {
      data.flightNumber = null;
    }

    booleanStringsToValues(data, ['bulkhead', 'exitRow']);

    updateUserFlight({ variables: data });
    setSubmitting(true);
  };

  return (
    <Container>
      {userErrors && (
        <Alert key="danger" variant="danger">
          {userErrors[0].message}
        </Alert>
      )}
      <h2>Edit {userFlight.ident} | {userFlight.origin?.iata} - {userFlight.destination?.iata}</h2>
      <center>
        <div className="form-container">
          {cannotLookupReason && (
            <Alert variant="info">
              {cannotLookupReason}
            </Alert>
          )}
          <DataState.Wrapper loading={submitting}>
            <EditForm onSubmit={onSubmit} userFlightData={userFlightData} userFlight={userFlight} />
          </DataState.Wrapper>
        </div>
      </center>
    </Container>
  );
};

export default EditFlight;

import { gql } from '@apollo/client';

import {
  WITH_COUNT_VARIABLES,
  WITH_COUNT_ARGUMENTS
} from '../../../queries/withCount';

import { HOTEL_FIELDS } from '../../Hotels/queries';

export const HOTELS = gql`
  ${HOTEL_FIELDS}
  query HotelsWithCount(
    ${WITH_COUNT_VARIABLES}
  ) {
    hotelsWithCount(
      ${WITH_COUNT_ARGUMENTS}
    ) {
      entries {
        ...HotelFields
      }
      count
    }
  }
`;

export const HOTEL = gql`
  ${HOTEL_FIELDS}
  query Hotel($id: ID!) {
    hotel(id: $id) {
      ...HotelFields
    }
  }
`;

const HOTEL_VARIABLES = `
  $name: String!
  $hotelChainId: ID
  $street: String
  $city: String
  $state: String
  $postalCode: String
  $country: String
  $status: String
  $marker: Upload
  $url: String
  $roomCount: Int
  $phoneNumber: String
  $approvedLogo: Upload
`;

const HOTEL_ARGUMENTS = `
  name: $name
  hotelChainId: $hotelChainId
  street: $street
  city: $city
  state: $state
  postalCode: $postalCode
  country: $country
  status: $status
  marker: $marker
  url: $url
  roomCount: $roomCount
  phoneNumber: $phoneNumber
  approvedLogo: $approvedLogo
`;

export const CREATE_HOTEL = gql`
  ${HOTEL_FIELDS}
  mutation CreateHotel(
    ${HOTEL_VARIABLES}
  ) {
    createHotel(
      ${HOTEL_ARGUMENTS}
    ) {
      hotel {
        ...HotelFields
      }
      errors { path, message }
    }
  }
`;

export const UPDATE_HOTEL = gql`
  ${HOTEL_FIELDS}
  mutation UpdateHotel(
    $id: ID!
    ${HOTEL_VARIABLES}
  ) {
    updateHotel(
      id: $id
      ${HOTEL_ARGUMENTS}
    ) {
      hotel {
        ...HotelFields
      }
      errors { path, message }
    }
  }
`;

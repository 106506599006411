import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ErrorMessage } from './ErrorBoundary';

const ActivityContainer = styled(View)`
  margin-left: auto;
  margin-right: auto;
  padding-top: 6px;
`;

const Warning = styled.div`
  border: 2px solid red;
`;

const WarningText = styled.div`
`;

export const Loading = () => {
  return (
    <ActivityContainer>
      <ActivityIndicator size="large" />
    </ActivityContainer>
  );
};

const Error = ({ error }) => {
  const navigate = useNavigate();

  const message = error.message || 'Unknown Error';

  if ('NOT_FOUND' === error.graphQLErrors?.[0]?.extensions?.code) {
    navigate('/');
  }

  return <ErrorMessage message={message} />;
};

const Wrapper = ({ data, loading, error, userErrors, children }) => {
  if (loading && !data) {
    return (
      <ActivityContainer>
        <ActivityIndicator size="large" />
      </ActivityContainer>
    );
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <>
      {userErrors && userErrors.length > 0 && (
        <Warning>
          {userErrors.map(error => (
            <WarningText key={error}>
              {error.path[error.path.length - 1]}
              {' '}
              {error.message}
            </WarningText>
          ))}
        </Warning>
      )}
      {children}
    </>
  );
};

const exports = { Loading, Error, Wrapper };
export default exports;

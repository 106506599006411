import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import DataTable from '@preflighttech/preflight-tables';

import ClientContext from '../../contexts/ClientContext';
import ActionLink from '../../elements/ActionLink';

import UserFileShowModal from './ShowModal';
import { DESTROY_USER_FILE } from './queries';
import useUserFilePath from './path';

const UserFiles = ({ resource, refetch }) => {
  const [viewUserFile, setViewUserFile] = useState();
  const { currentUser } = useContext(ClientContext);
  const isCurrentUser = resource.user.username === currentUser?.username;
  const userFilePath = useUserFilePath();

  const [destroyUserFile] = useMutation(DESTROY_USER_FILE,
    { onCompleted: refetch }
  );

  const confirmDelete = userFile => {
    if (window.confirm(`Really delete ${userFile.name}?`)) {
      destroyUserFile({ variables: { id: userFile.id }});
    }
  };

  const columns = [
    {
      key: 'name',
      label: 'Name',
      sort: 'asc',
    },
    {
      key: 'filename',
      label: 'File Name',
    },
    {
      key: 'type',
      label: 'Type',
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => {
        const editLink = userFilePath({
          resourceType: resource.__typename,
          resourceId: resource.id,
          suffix: `files/${entry.id}/edit`
        });

        return (
          <div style={{flexDirection: 'row'}}>
            <ActionLink handleClick={() => setViewUserFile(entry)}>
              View
            </ActionLink>
            {isCurrentUser && (
              <>
                {' | '}
                <Link to={editLink}>Edit</Link>
                {' | '}
                <ActionLink handleClick={() => confirmDelete(entry)}>
                  Delete
                </ActionLink>
              </>
            )}
          </div>
        );
      }
    },
  ];

  if (isCurrentUser) {
    columns.splice(
      2, 0,
      {
        key: 'visibility',
        label: 'Private?',
        value: ({ value }) => (
          'Private' === value ? 'Yes' : 'No'
        )
      }
    );
  }

  const newLink = userFilePath({
    resourceType: resource.__typename,
    resourceId: resource.id,
    suffix: 'files/new',
  });

  return (
    <>
      {isCurrentUser && (
        <Link to={newLink}>Add a File</Link>
      )}
      <DataTable.Simple
        data={resource.userFiles}
        columns={columns}
        pageLength="All"
        lengthMenu={false}
        disableSearch
        htmlTable
      />
      {viewUserFile && (
        <UserFileShowModal
          userFile={viewUserFile}
          handleHide={() => setViewUserFile(false)}
        />
      )}
    </>
  );
};

export default UserFiles;

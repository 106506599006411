import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Map from '../maps/singleLocation';

import DataState from '../DataState';
import parse from 'html-react-parser';

import {numberWithDelimiter} from '../../utils/stringHelpers';
import ClientContext from '../../contexts/ClientContext';

import { HOTEL } from './queries';

const Hotel = () => {
  const { slug } = useParams();

  const { data, loading, error, refetch, ...queryStatus } = useQuery(
    HOTEL, { variables: { slug } }
  );

  const { currentUser } = useContext(ClientContext);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const hotel = data.hotelBySlug;

  return (
    <>
      <DataState.Wrapper data={data} {...queryStatus}>
        <Container>
          <h2>{hotel.name}</h2>
        </Container>
        <Map 
          location={hotel}
          queryStatus={queryStatus}
          data={data}
        />
      </DataState.Wrapper>
    </>
  );
};

export default Hotel;

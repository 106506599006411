import React from 'react';
import { Row, Col } from 'react-bootstrap';

import UserErrors from '../../../elements/UserErrors';
import LoyaltyProgramSelect from '../LoyaltyPrograms/Select';

import HotelChainSelect from './Select';

const HotelChainForm = ({ register, control, userErrors }) => {
  return (
    <>
      {userErrors && <UserErrors userErrors={userErrors} />}

      <Row>
        <Col>
          Name
          <input
            type="text"
            {...register('name')}
          />

          Loyalty Program
          <LoyaltyProgramSelect
            control={control}
            name="loyaltyProgramId"
            scopes={['hotel']}
          />

          Parent Hotel Chain
          <HotelChainSelect control={control} name="hotelChainId" />

          About
          <input
            type="text"
            {...register('about')}
          />

          URL
          <input
            type="text"
            {...register('url')}
          />

          Logo
          <input
            type="file"
            {...register('approvedLogo')}
          />

          Map Marker
          <input
            type="file"
            {...register('marker')}
          />
        </Col>
      </Row>
    </>
  );
};

export default HotelChainForm;

import React from 'react';
import Modal from 'react-bootstrap/Modal';

const UserFileShowModal = ({ userFile, handleHide }) => {
  return (
    <Modal
      size="xl"
      centered
      show
      onHide={handleHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {userFile.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img
          src={userFile.url}
          alt={userFile.name}
          style={{maxWidth: '100%'}}
        />
      </Modal.Body>
    </Modal>
  );
};

export default UserFileShowModal;

import { gql } from '@apollo/client';

const CREDENTIAL_FIELDS = gql`
  fragment CredentialFields on Credential {
    accessToken
    client
    expiry
    tokenType
    uid
  }
`;

export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    username
    displayName
    email
    admin
  }
`;

export const CURRENT_USER = gql`
  ${USER_FIELDS}
  query CurrentUser {
    currentUser {
      ...UserFields
      activeSubscription {
        type
        expiresOn
        canceledAt
      }
    }
  }
`;

export const USER_LOGIN = gql`
  ${CREDENTIAL_FIELDS}
  mutation UserLogin(
    $email: String!
    $password: String!
  ) {
    userLogin(
      email: $email
      password: $password
    ) {
      credentials {
        ...CredentialFields
      }
    }
  }
`;

export const USER_LOGOUT = gql`
  mutation UserLogout {
    userLogout {
      authenticatable {
        id
      }
    }
  }
`;

export const REGISTER_USER = gql`
  mutation RegisterUser(
    $email: String!
    $username: String!
    $displayName: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    registerUser(
      email: $email
      username: $username
      displayName: $displayName
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      authenticatable {
        id
      }
    }
  }
`;

export const CONFIRM_REGISTRATION = gql`
  ${CREDENTIAL_FIELDS}
  mutation UserConfirmRegistrationWithToken($confirmationToken: String!) {
    userConfirmRegistrationWithToken(confirmationToken: $confirmationToken) {
      credentials {
        ...CredentialFields
      }
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation UpdateCurrentUser(
    $email: String
    $username: String
    $displayName: String
    $password: String
    $passwordConfirmation: String
  ) {
    updateCurrentUser(
      email: $email
      username: $username
      displayName: $displayName
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      user {
        id
      }
    }
  }
`;

export const SEND_RESET_PASSWORD = gql`
  mutation UserSendPasswordResetWithToken(
    $email: String!
    $redirectUrl: String!
  ) {
    userSendPasswordResetWithToken(
      email: $email
      redirectUrl: $redirectUrl
    ) {
      message
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  ${CREDENTIAL_FIELDS}
  mutation UserUpdatePasswordWithToken(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    userUpdatePasswordWithToken(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      credentials {
        ...CredentialFields
      }
    }
  }
`;

export const SETUP_CHECKOUT_SESSION = gql`
  mutation SetupCheckoutSession(
    $pricing: String!
  ) {
    setupCheckoutSession(
      pricing: $pricing
    ) {
      url
    }
  }
`;

export const CHANGE_SUBSCRIPTION_TYPE = gql`
  mutation ChangeSubscriptionType(
    $type: String!
  ) {
    changeSubscriptionType(
      type: $type
    ) {
      errors { message, path }
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription {
    cancelSubscription {
      errors { message, path }
    }
  }
`;

export const REINSTATE_SUBSCRIPTION = gql`
  mutation ReinstateSubscription {
    reinstateSubscription {
      errors { message, path }
    }
  }
`;

export const RESUME_UPLOAD_PROCESSING = gql`
  mutation ResumeUploadProcessing {
    resumeUploadProcessing {
      result
      message
    }
  }
`;

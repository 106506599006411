import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { DateTime } from 'luxon';
import ClientContext from '../../contexts/ClientContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const Home = () => {
 
  return (
    <Container>
      <img src="/assets/images/travel-social-logo.png" style={{width: "100%", maxWidth: "400px", paddingTop: "10px", paddingBottom: "20px"}}/>
      <h1 style={{color: "#044F94"}}>Support Travel Social</h1>
      <Row>
        <Col lg={6}>
          <div style={{padding: "56.25% 0 0 0", position: "relative"}}><iframe src="https://player.vimeo.com/video/822852391?h=4abed8f5a7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} title="TravelSocial"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          <br /><br />
          <hr />
          <h3 style={{color: "#28A9E1"}}>What is Travel Social?</h3>
          <div style={{textAlign: "justify"}}>Travel Social was born out of a desire to make the travel process smarter, more intuitive, and
          to be a whole lot more social.  In its early stages, Travel Social will be a combination of a travel
          journal and a review site for Airlines, Hotels, Restaurants, and Activities.  Users will be able
          to connect with their friends and share photos and reviews of the places they go, while keeping
          many more details about their travels than they are used to.</div>
          <hr />
          <h3 style={{color: "#28A9E1"}}>We've got big plans for Travel Social.</h3>
          <div style={{textAlign: "justify"}}>
            <b>Travel Help.</b> Are you the kind of person who wants to travel and tell the world about it?  
            Or are you the kind of person who wants to go on a trip, but hates planning the details?  This 
            site is for both of you.  Our goal is to create a community where experienced travellers can 
            help novice travellers create the trip of a lifetime.<br /><br />

            <b>Travel Together.</b> This won't be a dating site, but this will be a place to create and 
            connect with friends from around the globe.  Share which flights you'll be on with your close
            friends and find seats together when you're headed the same way. Build a bond with an Avgeek
            from another country and find a cafe to meet at in Paris.<br /><br />

            <b>Travel Smart.</b> We believe that travel
            should be made easier with the help of technology.  Our goal is to create your own personal travel
            assistant—an app in your pocket that helps you research, plan, and experience the trip of 
            you desire.
          </div>
          <hr />
          <h3 style={{color: "#28A9E1"}}>Come on this Journey With Us.</h3>
          <div style={{textAlign: "justify"}}>
            <b>We're looking for Beta Testers</b> and supportors.  You can support Travel Social and
            become a Beta Tester using the blue buttons on the right (or below if you're on your phone). Depending on the level of support
            you choose, you can get a Travel Social sticker or access to the site before anyone else.
            There will also be several Premium Features that you can get access to with the right donation.
            Our lawyers want to make it clear that if you choose any of these options, you only get what
            is promised to you in within the option.  These are not offers for equity stake in Travel Social.<br /><br />


            <b>We're also looking for Investors</b>. We are looking for Investors who are interested in investing 
            $50,000.00 and up to $500,000.00 in exchange for equity in Travel Social. If you're interested in learning 
            more about an equity stake, please email mike[at]travel.social.<br />
            <hr />
          </div>
        </Col>
        <Col lg={6}>
          <h5>How would you like to support Travel Social?</h5> 
          <a href="https://b5c056.myshopify.com/cart/46435132670250:1?channel=buy_button">
            <div class="stripe-box">
              <h5>Click here to buy a sticker for $7.00</h5>
              <img src="/assets/images/Sticker.jpg" style={{width: "100%"}}/>
            </div>
          </a>
          <a href="https://buy.stripe.com/6oE8A33oj4nw35e9AE">
            <div class="stripe-box">
              <h5>Get a Travel Social Sticker</h5>
              <b>$20.00</b> - For 20 bucks, we'll send you a Travel Social sticker.  
              The rest of the funds will be used to continue to develop Travel Social!  
            </div>
          </a>
          <a href="https://buy.stripe.com/5kA3fJaQL8DMbBKbIL">
            <div class="stripe-box">
              <h5>Be a Confirmed Beta Tester</h5>
              <b>$100.00</b> - We're close to beta testing, and for one-hundred bucks,
              you can get first access to the website!  You'll be able to add flights,
              hotels, and more!  You will be part of the group who gets to define what's
              good and what needs to go.  We're also going to send you a sticker!
            </div>
          </a>
          <a href="https://buy.stripe.com/cN2g2v1gbaLU35e28a">
            <div class="stripe-box">
              <h5>Get a Year of Premium Features</h5>
              <b>$250.00</b> - Don't just be a beta tester, also get all of the premium 
              features for a full year AFTER we launch the site to the public! This includes
              everything listed above (the sticker and the beta testing), plus one full year
              of premium features.  We're not totally sure what all of those are yet, but
              we know they'll be pretty cool!
            </div>
          </a>
          <a href="https://buy.stripe.com/fZe17B1gbcU249icMN">
            <div class="stripe-box">
              <h5>Get Two Years of Premium Features</h5>
              <b>$500.00</b> - This is just like the $250 option, but it guarantees you
              two full years of premium features!
            </div>
          </a>
          <a href="https://buy.stripe.com/aEU4jN1gbg6ecFO3cc">
            <div class="stripe-box">
              <h5>Get Five Years and Premium Features First</h5>
              <b>$1,000.00</b> - You get a sticker!  You get to beta test!  You get <b>FIVE</b> years 
              of premium features!  AND, during those five years, you'll get to test
              all new features BEFORE anyone else!  We'll also throw in priority help when you're
              experiencing any sort of issues with the site.
            </div>
          </a>
          <a href="https://buy.stripe.com/3cs9E75wr3js8py28d">
            <div class="stripe-box">
              <h5>Support Us with Any Amount</h5>
              <b>Pick any amount</b> - Just want to give and receive nothing in return?
              Pick any amount here and you'll get the most important gift, our love! <i class="fa-solid fa-heart"></i>
            </div>
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;

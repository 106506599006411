import React from 'react';
import { Row, Col } from 'react-bootstrap';

import UserErrors from '../../../elements/UserErrors';
import LoyaltyProgramSelect from '../LoyaltyPrograms/Select';

const AirlineForm = ({ register, control, userErrors }) => {
  return (
    <>
      {userErrors && <UserErrors userErrors={userErrors} />}

      <Row>
        <Col>
          Name
          <input
            type="text"
            {...register('name')}
          />

          ICAO
          <input
            type="text"
            maxlength="3"
            {...register('icao')}
          />

          IATA
          <input
            type="text"
            maxlength="2"
            {...register('iata')}
          />

          Callsign
          <input
            type="text"
            {...register('callsign')}
          />

          Country (2-letter code)
          <input
            type="text"
            maxlength="2"
            {...register('country')}
          />

          Loyalty Program
          <LoyaltyProgramSelect
            control={control}
            name="loyaltyProgramId"
            scopes={['airline']}
          />

          About
          <input
            type="text"
            {...register('about')}
          />

          URL
          <input
            type="text"
            {...register('url')}
          />

          Logo
          <input
            type="file"
            {...register('approvedLogo')}
          />

          Map Marker
          <input
            type="file"
            {...register('marker')}
          />
        </Col>
      </Row>
    </>
  );
};

export default AirlineForm;

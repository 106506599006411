import { gql } from '@apollo/client';

import { HOTEL_CORE_FIELDS } from '../../Hotels/queries';
import { REVIEW_FIELDS } from '../../Reviews/queries';

const AUTOCOMPLETE_FIELDS = `
  hotels {
    id
    slug
    name
  }
`;

export const USER_FLIGHTS_FILTER_DATA = gql`
  query {
    ${AUTOCOMPLETE_FIELDS}
  }
`;

const STAY_CORE_FIELDS = gql`
  fragment StayCoreFields on Stay {
    id
    experienceId
    type
    roomNumber
    checkInOn
    checkOutOn
    nightCount
    roomCharge
    taxCharge
    incidentalsCharge
    totalCharge
    nightsEarned
    staysEarned
    pointsEarned
    pointsUsed
    note
    reason
  }
`;

export const STAYS = gql`
  ${STAY_CORE_FIELDS}
  query Stays(
    $username: String!
    $filters: UserFlightFilters
    $sort: String
    $sortDirection: String
  ) {
    stays(
      username: $username
      filters: $filters
      sort: $sort
      sortDirection: $sortDirection
    ) {
      ...StayCoreFields
      hotel { id, name, location }
    }
    userByUsername(username: $username) {
      displayName
    }
    stayStats(
      username: $username
      filters: $filters
    ){
      stays
      stayYears { year, stays }
      hotels
      hotelChains
      averageNights
      nights
      cities
      countries
      graphPoints {
        key
        flights
        dow
        month
        airline
        plane
      }
    }
    staysForMap(
      username: $username
      filters: $filters
    ) {
      markers {
        image
        infoBox
        latitude
        longitude
        idx
      }
    }
  }
`;

export const STAY = gql`
  ${STAY_CORE_FIELDS}
  ${HOTEL_CORE_FIELDS}
  ${REVIEW_FIELDS}
  query Stay($id: ID!) {
    stay(id: $id) {
      ...StayCoreFields
      hotel {
        ...HotelCoreFields
        hotelChain {
          id
          entityId
          name
          loyaltyProgram {
            id
            entityId
            name
          }
        }
      }
      reviews {
        ...ReviewFields
      }
    }
  }
`;

export const USER_FLIGHT = gql`
  query UserFlight($id: ID!) {
    userFlight(id: $id) {
      id
      origin { id, name, icao, iata }
      destination { id, name, icao, iata }
      airline { id, name, icao, iata, logoUri }
      operator { id, name, icao, iata }
      planeType { id, name, icao, iata }
      tailNumber
      departureAt
      departureTerminal
      departureGate
      arrivalAt
      arrivalTerminal
      arrivalGate
      ident
      seatNumber
      flightNumber
      cabin
      bagCount
      bagClaim
      seatNumber
      exitRow
      bulkhead
      seatType
      reason
      notes
      ticketNumber
      pnr
      fareClass
      fare
      taxes
      qualifyingMilesEarned
      milesEarned
      spendEarned
      segmentsEarned
      nextFlightId
      nextFlightLinkText
      previousFlightId
      previousFlightLinkText
      departureTimeString
      arrivalTimeString
      actualFlightTime
      distanceMiles
      distanceKm
      flightsWithThisCityPair
      flightsWithThisFlightNumber
      flightsWithThisPlane
      flightsWithThisPlaneType
      flightsWithThisOperator
      flightsWithThisAirline
      flightsWithThisFlightNumber
    }
    flightsForMap(
      id: $id
    ) {
      routes {
        origin {
          latitude
          longitude
        }
        destination {
          latitude
          longitude
        }
        numberOfFlights
        lineColor
        infoBox
        idx
        actualTrackPoints {
          latitude
          longitude
        }
        directPath
      }
      markers {
        image
        infoBox
        latitude
        longitude
        idx
      }
    }
  }
`;

const STAY_VARIABLES = `
  $hotelId: ID
  $hotel: HotelInput
  $type: String!
  $roomNumber: String
  $checkInOn: ISO8601Date
  $checkOutOn: ISO8601Date
  $nightCount: Int
  $roomCharge: Decimal
  $taxCharge: Decimal
  $incidentalsCharge: Decimal
  $nightsEarned: Int
  $staysEarned: Int
  $pointsEarned: Int
  $pointsUsed: Int
  $note: String
  $reason: String
`;

const STAY_ARGUMENTS = `
  hotelId: $hotelId
  hotelAttributes: $hotel
  type: $type
  roomNumber: $roomNumber
  checkInOn: $checkInOn
  checkOutOn: $checkOutOn
  nightCount: $nightCount
  roomCharge: $roomCharge
  taxCharge: $taxCharge
  incidentalsCharge: $incidentalsCharge
  nightsEarned: $nightsEarned
  staysEarned: $staysEarned
  pointsEarned: $pointsEarned
  pointsUsed: $pointsUsed
  note: $note
  reason: $reason
`;

export const CREATE_STAY = gql`
  ${STAY_CORE_FIELDS}
  mutation CreateStay(
    ${STAY_VARIABLES}
  ) {
    createStay(
      ${STAY_ARGUMENTS}
    ) {
      stay {
        ...StayCoreFields
      }
      errors { path, message }
    }
  }
`;

export const UPDATE_STAY = gql`
  ${STAY_CORE_FIELDS}
  mutation UpdateStay(
    $id: ID!
    ${STAY_VARIABLES}
  ) {
    updateStay(
      id: $id
      ${STAY_ARGUMENTS}
    ) {
      stay {
        ...StayCoreFields
      }
      errors { path, message }
    }
  }
`;

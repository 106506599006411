import { gql } from '@apollo/client';

export const REVIEW_FIELDS = gql`
  fragment ReviewFields on Review {
    id
    entity {
      ... on Airline {
        id
        name
      }
      ... on Airport {
        id
        name
      }
      ... on Hotel {
        id
        name
      }
      ... on HotelChain {
        id
        name
      }
      ... on LoyaltyProgram {
        id
        name
      }
      ... on PlaneType {
        id
        name
      }
    }
    rating
    subject
    body
    visibility
  }
`;

export const CREATE_REVIEW = gql`
  ${REVIEW_FIELDS}
  mutation CreateReview (
    $entityId: ID!
    $experienceId: ID
    $rating: Int!
  ) {
    createReview(
      entityId: $entityId
      experienceId: $experienceId
      rating: $rating
    ) {
      review {
        ...ReviewFields
      }
      errors { path, message }
    }
  }
`;

export const UPDATE_REVIEW = gql`
  ${REVIEW_FIELDS}
  mutation UpdateReview (
    $id: ID!
    $rating: Int
    $subject: String
    $body: String
    $visibility: String
  ) {
    updateReview(
      id: $id
      rating: $rating
      subject: $subject
      body: $body
      visibility: $visibility
    ) {
      review {
        ...ReviewFields
      }
      errors { path, message }
    }
  }
`;

import { gql } from '@apollo/client';

import {
  WITH_COUNT_VARIABLES,
  WITH_COUNT_ARGUMENTS
} from '../../../queries/withCount';

const HOTEL_CHAIN_CORE_FIELDS = gql`
  fragment HotelChainCoreFields on HotelChain {
    id
    name
    slug
    about
    url
    marker
    logoUri
    hotelChain { id, name }
    loyaltyProgram { id, name }
  }
`;

export const HOTEL_CHAINS = gql`
  ${HOTEL_CHAIN_CORE_FIELDS}
  query HotelChainsWithCount(
    ${WITH_COUNT_VARIABLES}
  ) {
    hotelChainsWithCount(
      ${WITH_COUNT_ARGUMENTS}
    ) {
      entries {
        ...HotelChainCoreFields
        hotels { id }
      }
      count
    }
  }
`;

export const HOTEL_CHAINS_SELECT = gql`
  query NoUserLogHotelChains {
    hotelChains {
      value: id
      label: name
    }
  }
`;

export const HOTEL_CHAIN = gql`
  ${HOTEL_CHAIN_CORE_FIELDS}
  query HotelChain($id: ID!) {
    hotelChain(id: $id) {
      ...HotelChainCoreFields
    }
  }
`;

const HOTEL_CHAIN_VARIABLES = `
  $name: String!
  $about: String
  $hotelChainId: ID
  $loyaltyProgramId: ID
  $marker: Upload
  $url: String
  $approvedLogo: Upload
`;

const HOTEL_CHAIN_ARGUMENTS = `
  name: $name
  about: $about
  hotelChainId: $hotelChainId
  loyaltyProgramId: $loyaltyProgramId
  marker: $marker
  url: $url
  approvedLogo: $approvedLogo
`;

export const CREATE_HOTEL_CHAIN = gql`
  ${HOTEL_CHAIN_CORE_FIELDS}
  mutation CreateHotelChain(
    ${HOTEL_CHAIN_VARIABLES}
  ) {
    createHotelChain(
      ${HOTEL_CHAIN_ARGUMENTS}
    ) {
      hotelChain {
        ...HotelChainCoreFields
      }
      errors { path, message }
    }
  }
`;

export const UPDATE_HOTEL_CHAIN = gql`
  ${HOTEL_CHAIN_CORE_FIELDS}
  mutation UpdateHotelChain(
    $id: ID!
    ${HOTEL_CHAIN_VARIABLES}
  ) {
    updateHotelChain(
      id: $id
      ${HOTEL_CHAIN_ARGUMENTS}
    ) {
      hotelChain {
        ...HotelChainCoreFields
      }
      errors { path, message }
    }
  }
`;

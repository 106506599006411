import React, { useContext } from 'react';
import DataTable from '@preflighttech/preflight-tables';
import { useNavigate, Link } from 'react-router-dom';
import ClientContext from '../../../contexts/ClientContext';

import { AIRLINES } from './queries';

const columns = [
  {
    key: 'id',
    label: 'ID'
  },
  {
    key: 'name',
    label: 'Name',
    sort: 'asc'
  },
  {
    key: 'icao',
    label: 'ICAO'
  },
  {
    key: 'iata',
    label: 'IATA'
  },
  {
    key: 'url',
    label: 'URL'
  },
  {
    key: 'logoUri',
    label: 'Logo',
    content: ({ value }) => (
      value && <img src={value} alt="Logo" style={{maxHeight: 100}} />
    ),
  },
  {
    key: 'marker',
    label: 'Marker',
    content: ({ value }) => (
      value && <img src={value} alt="Marker" style={{maxHeight: 100}} />
    ),
  },
  {
    key: 'actions',
    label: 'Actions',
    sort: 'prevent',
    content: ({ entry }) => (
      <Link to={`/admin/airlines/${entry.id}/edit`}>Edit</Link>
    ),
  },
];

const AdminAirlines = () => {
  const { currentUser } = useContext(ClientContext);
  const navigate = useNavigate();

  if (!currentUser) {
    return null
  }
  if (currentUser?.admin !== true) {
    navigate('/');
    return false;
  }

  return (
    <>
      <a href="/admin/airlines/new">Add a New Airline</a>
      <DataTable.Gql
        query={AIRLINES}
        queryName="airlinesWithCount"
        columns={columns}
        htmlTable
        pageLength={25}
        lengthMenu={ [10, 25, 50, 'All'] }
      />
    </>
  );
};

export default AdminAirlines;

import React, { createContext, useCallback, useEffect, useState } from 'react';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import config from '../config';
import { sendError } from '../components/ErrorBoundary';
import { CURRENT_USER } from '../components/Profile/queries';

export const ClientContext = createContext();

const IGNORE_ERRORS = ['USER_ERROR', 'NOT_FOUND'];

const setupApolloClient = ({ credentials }) => {
  let headers = {};

  if (credentials?.accessToken) {
    headers = {
      ...credentials,
      'access-token': credentials.accessToken,
    };
  }

  const uploadLink = createUploadLink({
    uri: config.api.uri,
    headers: headers
  });

  const checkHeadersLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(data => {
      const context = operation.getContext();

      if (context.response?.headers) {
        localStorage.setItem(
          'X-Request-Id',
          context.response.headers.get('X-Request-Id')
        );
      }

      return data;
    });
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors && graphQLErrors[0]) {
      if (!IGNORE_ERRORS.includes(graphQLErrors[0].extensions?.code)) {
        sendError({
          error: {
            name: 'GraphQL Error',
            message: graphQLErrors[0].message,
            stack: JSON.stringify(graphQLErrors),
          }
        });
      }
    }
  });

  const client = new ApolloClient({
    link: from([checkHeadersLink, errorLink, uploadLink]),
    cache: new InMemoryCache(),
  });

  client.hasCredentials = !!(credentials.accessToken);

  return client;
};

export const ClientProvider = ({ children }) => {
  const [client, setClient] = useState();
  const [credentials, setCredentials] = useState({});
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const storedCredentials = localStorage.getItem('ts-credentials');

    if (storedCredentials) {
      setCredentials(JSON.parse(storedCredentials));
    } else {
      setCurrentUser(false);
    }
  }, []);

  const updateCredentials = useCallback(value => {
    localStorage.setItem('ts-credentials', JSON.stringify(value));

    if (credentials?.accessToken !== value?.accessToken) {
      setCredentials(value);

      if (!value.accessToken) {
        setCurrentUser(false);
      }
    }
  }, [credentials]);

  useEffect(() => {
    setClient(setupApolloClient({ credentials }));
  }, [credentials]);

  const refetchCurrentUser = useCallback(() => {
    client.query({
      query: CURRENT_USER,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      if (data.currentUser) {
        setCurrentUser(data.currentUser);
      } else {
        setCurrentUser(false);
        setCredentials({});
      }
    });
  }, [client]);

  useEffect(() => {
    if (client?.hasCredentials) {
      refetchCurrentUser();
    }
  }, [client]);

  const value = {
    client,
    credentials,
    updateCredentials,
    currentUser,
    refetchCurrentUser
  };

  return (
    <ClientContext.Provider value={value}>
      {client ? (
        <ApolloProvider client={client}>
          {children}
        </ApolloProvider>
      ) : (
        <div>Loading</div>
      )}
    </ClientContext.Provider>
  );
};

export default ClientContext;

import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import ClientContext from '../../contexts/ClientContext';

import { USER_LOGOUT } from '../Profile/queries';

const Menu = () => {
  const { currentUser, updateCredentials } = useContext(ClientContext);

  const [logout] = useMutation(USER_LOGOUT, {
    onCompleted: () => updateCredentials({}),
    onError: error => {
      console.log('Logout Error', error);
      updateCredentials({});
    }
  });

  const handleLogout = e => {
    e.preventDefault();
    e.stopPropagation();
    logout();
  }

  return (
        <>  
          <Navbar bg="light" expand="lg" sticky="top">
            <Navbar.Brand href="/"><img src="/assets/images/travel-social-logo.png" height="30" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
               <Nav className="me-auto">
                <NavDropdown title="Plan A Trip" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/airports"><i class="fa-solid fa-tower-control"></i> Airports</NavDropdown.Item>
                  <NavDropdown.Item href="/airlines"><i class="fa-regular fa-plane-tail"></i> Airlines</NavDropdown.Item>
                  <NavDropdown.Item href="/hotels" style={{color: "#A17BD4"}}><i class="fa-solid fa-hotel"></i> Hotels</NavDropdown.Item>
                  <NavDropdown.Divider />
                  Coming Soon
                  <NavDropdown.Item href="/">Restaurants</NavDropdown.Item>
                  <NavDropdown.Item href="/">Activities</NavDropdown.Item>
                </NavDropdown>
                {currentUser && (
                  <>
                    <NavDropdown title="Your Trips" id="basic-nav-dropdown">
                      <div style={{padding: "4px"}}>
                        <h4><i class="fa-solid fa-plane-up"></i> Flights</h4>
                        <NavDropdown.Item href={`/journeys/${currentUser.username}/flights`}>View Your Flights</NavDropdown.Item>
                        <NavDropdown.Item href={`/journeys/${currentUser.username}/flights/new`}>Add A Flight</NavDropdown.Item>
                        <NavDropdown.Item href={`/journeys/${currentUser.username}/flights/upload`}>Upload Flights</NavDropdown.Item>
                        <hr />
                        <h4 style={{color: "#A17BD4"}}><i class="fa-solid fa-hotel"></i> Stays</h4>
                        <NavDropdown.Item href={`/journeys/${currentUser.username}/stays`}>View Your Stays</NavDropdown.Item>
                        <NavDropdown.Item href={`/journeys/${currentUser.username}/stays/new`}>Add A Hotel Stay</NavDropdown.Item>
                        <NavDropdown.Divider />
                        Coming Soon
                        <NavDropdown.Item href="/">Add A Meal</NavDropdown.Item>
                        <NavDropdown.Item href="/">Add An Activity</NavDropdown.Item>
                      </div>
                    </NavDropdown>
                    {currentUser.admin === true &&
                      <NavDropdown title="Admin" id="basic-nav-dropdown">
                        <NavDropdown.Item href={`/admin/loyaltyPrograms`}>Loyalty Programs</NavDropdown.Item>
                        <NavDropdown.Item href={`/admin/airlines`}>Airlines</NavDropdown.Item>
                        <NavDropdown.Item href={`/admin/hotelChains`}>Hotel Chains</NavDropdown.Item>
                        <NavDropdown.Item href={`/admin/hotels`}>Hotels</NavDropdown.Item>
                        
                      </NavDropdown>
                    }
                  </>
                )}
              </Nav>
              <Nav>
                <Nav.Link href="/feedback"><i class="fa-solid fa-message-pen"></i> Give Us Feedback</Nav.Link>
                <Nav.Link href="/support-travel-social"><i className="fad fa-hands-usd"></i> Support travel.social</Nav.Link>
                {currentUser && (
                  <>
                    <NavDropdown title={`${currentUser.displayName}`} id="basic-nav-dropdown" style={{right: 0, left: 'auto'}}>
                      <NavDropdown.Item href={`/journeys/${currentUser.username}/flights`}>Your Profile</NavDropdown.Item>
                      <NavDropdown.Item href="/profile/edit">
                        Update Account
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/profile/premium/account">
                        Premium Access
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/" onClick={e => handleLogout(e)}>
                        Sign Out
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}
                {!currentUser && (
                  <>
                    <Nav.Link href="/profile/login">Login</Nav.Link>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>

        </>

  );
};

export default Menu;

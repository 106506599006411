import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

const UserErrors = ({ userErrors }) => {
  return (
    <Row>
      <Col>
        <Alert key="danger" variant="danger">
          <ol>
            {userErrors.map(({ path, message }) => (
              <li key={path}>
                {path.join(' ')}: {message}
              </li>
            ))}
          </ol>
        </Alert>
      </Col>
    </Row>
  );
};

export default UserErrors;

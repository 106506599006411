import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom'

import { Loading } from '../components/DataState';
import ClientContext from '../contexts/ClientContext';

const AdminRoutes = ({ children }) => {
  const { currentUser } = useContext(ClientContext);

  if (false === currentUser) {
    return <Navigate to="/profile/login" />;
  }

  if (!currentUser) { // i.e. null or undefined because still loading
    return <Loading />;
  }

  if (!currentUser.admin) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default AdminRoutes;

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';

const ReviewDetail = ({ review, handleHide, updateReview }) => {
  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      subject: (review.subject || ''),
      body: (review.body || ''),
      visibility: review.visibility,
    }
  });

  const onSubmit = data => {
    updateReview({
      variables: {
        id: review.id,
        ...data
      },
    });

    handleHide();
  };

  return (
    <Modal
      size="xl"
      centered
      show
      onHide={handleHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Review {review.entity.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="ts-form">
          Subject
          <input type="text" {...register('subject')} />

          Details
          <textarea {...register('body')} />

          Private
          <Controller
            control={control}
            name="visibility"
            render={({ field: { value, onChange }}) => (
              <input
                style={{width: '2em'}}
                type="checkbox"
                checked={value === 'Private'}
                onChange={e => (
                  onChange(e.target.checked ? 'Private' : 'Public')
                )}
              />
            )}
          />

          <input
            value="Save Review"
            type="button"
            id="submit-button"
            onClick={handleSubmit(onSubmit)}
          />
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ReviewDetail;

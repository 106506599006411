import { gql } from '@apollo/client';

import {
  WITH_COUNT_VARIABLES,
  WITH_COUNT_ARGUMENTS
} from '../../../queries/withCount';

const LOYALTY_PROGRAM_CORE_FIELDS = gql`
  fragment LoyaltyProgramCoreFields on LoyaltyProgram {
    id
    companyName
    name
    url
    logoUri
  }
`;

export const LOYALTY_PROGRAMS = gql`
  ${LOYALTY_PROGRAM_CORE_FIELDS}
  query LoyaltyProgramsWithCount(
    ${WITH_COUNT_VARIABLES}
  ) {
    loyaltyProgramsWithCount(
      ${WITH_COUNT_ARGUMENTS}
    ) {
      entries {
        ...LoyaltyProgramCoreFields
      }
      count
    }
  }
`;

export const LOYALTY_PROGRAM = gql`
  ${LOYALTY_PROGRAM_CORE_FIELDS}
  query LoyaltyProgram($id: ID!) {
    loyaltyProgram(id: $id) {
      ...LoyaltyProgramCoreFields
    }
  }
`;

export const LOYALTY_PROGRAMS_SELECT = gql`
  query NoUserLogLoyaltyPrograms($scopes: [String!]) {
    loyaltyPrograms(scopes: $scopes) {
      value: id
      label: name
    }
  }
`;

const LOYALTY_PROGRAM_VARIABLES =`
  $name: String
  $companyName: String
  $url: String
  $programType: String
  $logo: Upload
`;

const LOYALTY_PROGRAM_ARGUMENTS =`
  name: $name
  companyName: $companyName
  url: $url
  programType: $programType
  logo: $logo
`;

export const CREATE_LOYALTY_PROGRAM = gql`
  ${LOYALTY_PROGRAM_CORE_FIELDS}
  mutation CreateLoyaltyProgram(
    ${LOYALTY_PROGRAM_VARIABLES}
  ) {
    createLoyaltyProgram(
      ${LOYALTY_PROGRAM_ARGUMENTS}
    ) {
      loyaltyProgram {
        ...LoyaltyProgramCoreFields
      }
      userErrors { path, message }
    }
  }
`;

export const UPDATE_LOYALTY_PROGRAM = gql`
  ${LOYALTY_PROGRAM_CORE_FIELDS}
  mutation UpdateLoyaltyProgram(
    $id: ID!
    ${LOYALTY_PROGRAM_VARIABLES}
  ) {
    updateLoyaltyProgram(
      id: $id
      ${LOYALTY_PROGRAM_ARGUMENTS}
    ) {
      loyaltyProgram {
        ...LoyaltyProgramCoreFields
      }
      errors { path, message }
    }
  }
`;

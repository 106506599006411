import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import Container from 'react-bootstrap/Container';

import DataState from '../../DataState';
import ClientContext from '../../../contexts/ClientContext';

import Form, { prepareDataForSubmit } from './form';
import { STAY, UPDATE_STAY } from './queries';

const EditStay = () => {
  const { id, username } = useParams();
  const { currentUser } = useContext(ClientContext);
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState();

  const { register, handleSubmit, control, reset, setValue } = useForm();

  const { data } = useQuery(STAY, { variables: { id } });

  useEffect(() => {
    if (data?.stay) {
      reset({
        id: data.stay.id,
        hotelId: data.stay.hotel.id,
        type: data.stay.type,
        roomNumber: data.stay.roomNumber,
        checkInOn: data.stay.checkInOn,
        checkOutOn: data.stay.checkOutOn,
        nightCount: data.stay.nightCount,
        roomCharge: data.stay.roomCharge,
        taxCharge: data.stay.taxCharge,
        incidentalsCharge: data.stay.incidentalsCharge,
        nightsEarned: data.stay.nightsEarned,
        staysEarned: data.stay.staysEarned,
        pointsEarned: data.stay.pointsEarned,
        pointsUsed: data.stay.pointsUsed,
        note: data.stay.note,
        reason: data.stay.reason,
        hotel: {
          name: data.stay.hotel.name,
          street: data.stay.hotel.street,
          city: data.stay.hotel.city,
          state: data.stay.hotel.state,
          postalCode: data.stay.hotel.postalCode,
          country: data.stay.hotel.country,
        }
      });
    }
  }, [data]);

  const [updateStay, { error }] = useMutation(UPDATE_STAY, {
    onCompleted: data => {
      navigate(`/journeys/${currentUser.username}/stays`);
    }
  });

  if (!currentUser) {
    return null;
  } else if (currentUser?.username !== username) {
    navigate(`/journeys/${currentUser.username}/stays/new`);
    return null;
  }

  const onSubmit = data => {
    prepareDataForSubmit(data);
    updateStay({ variables: data });
    setSubmitting(true);
  };

  return (
    <Container>
      <h2>Edit Stay</h2>
      <center>
        <div className="form-container">
          <DataState.Wrapper loading={submitting}>
            {data?.stay && (
              <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
                <Form
                  register={register}
                  control={control}
                  setValue={setValue}
                  stay={data?.stay}
                />

                <input value="Update Stay" type="submit" id="submit-button" />
              </form>
            )}
          </DataState.Wrapper>
        </div>
      </center>
    </Container>
  );
};

export default EditStay;

import React from 'react';
import { useQuery } from '@apollo/client';
import DataTable from '@preflighttech/preflight-tables';

import { AIRLINES } from './queries';

const columns = [
  {
    key: 'name',
    label: 'Name',
    sort: 'asc',
    content: ({ entry }) => {
      return <a href={`/airlines/${entry.slug}`}>{entry.name}</a>
    }
  },
  {
    key: 'icao',
    label: 'ICAO'
  },
  {
    key: 'iata',
    label: 'IATA'
  },
  
];

const Airlines = () => {
  const { data } = useQuery(AIRLINES);

  return (
    <div class="container">
      <h3><i class="fa-regular fa-plane-tail"></i> Airlines</h3>
      <DataTable.Gql
        query={AIRLINES}
        queryName="airlinesWithCount"
        columns={columns}
        htmlTable
        pageLength={25}
        lengthMenu={ [10, 25, 50, 'All'] }
      />
    </div>
  );
};

export default Airlines;

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Alert from 'react-bootstrap/Alert';
import { useForm, Controller } from 'react-hook-form';

import UserErrors from '../../../elements/UserErrors';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiAutocomplete-listbox": {
        minHeight: 400,
        color: "black",
        fontSize: 14,
        padding: "none",
        "& :hover": {
          color: "brown"
        },
        "& li": {
          //list item specific styling
        }
      }
    },
    filter: {
      "&.MuiAutocomplete-root": {
        marginBottom: "15px",
      },
    },
    textfield: {
      "& .MuiOutlinedInput-root": {
        padding: "5px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "2px solid black",
        borderRadius: "0px",
        margin: "0px",
        marginBottom: "5px"
      },
      "& .MuiInputBase-input.MuiAutocomplete-input": {
        color: "black",
        fontSize: 14,
        padding: "5px",
        margin: "0px",
        border: "none"
      },
      "& #custom-autocomplete-label": {
        //or could be targeted through a class
        color: "red"
      },
      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
        color: "red"
      }
    }
  })
);

export const CustomPopper = function (props) {
  const classes = useStyles();
  return <Popper {...props} className={classes.root} placement="bottom" />;
};

const AirlineAirportForm = ({ register, control, userErrors, airportsData, airlineId }) => {
  const classes = useStyles();
  const airports = airportsData.map((airport) => {
    return { value: airport.id, label: `${airport.name || ""} (${airport.icao || ""}/${airport.iata || ""})` }
  })

  return (
    <>
      {userErrors && <UserErrors userErrors={userErrors} />}

      <Row>
        <Col>
          Airport
          <Controller
            control={control}
            name="airportId"
            render={({ field }) => (
              <Autocomplete
                disablePortal
                id="custom-autocomplete"
                options={airports}
                sx={{ width: "100%"}}
                isOptionEqualToValue={(option, selected) => (
                  option.value === selected?.value
                )}
                onChange={(e, selected) => (
                  field.onChange(selected?.value || null)
                )}
                renderInput={(params) => <TextField {...params} variant="outlined" className={classes.textfield} />}
                PopperComponent={CustomPopper}
                value={``}
              />
            )}
          />

          Is this a hub?
          <select
            style={{border: "2px solid black"}}
            {...register('hub')}>
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
          Is this a focus city?
          <select
            style={{border: "2px solid black"}}
            {...register('focusCity')}>
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
          <input
            type="hidden"
            value={airlineId}
            {...register('id')}
          />
          
        </Col>
      </Row>
    </>
  );
};

export default AirlineAirportForm;

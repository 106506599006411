import React from 'react';

const ValidationErrors = ({ name, errors }) => {
  if (errors?.[name]) {
    return (
      <div style={{color: 'red'}}>
        {errors[name].message || 'Required'}
      </div>
    );
  } else {
    return null;
  }
};

export default ValidationErrors;

import moment from "moment";

/*
    This function is used to set dates in indices in a way that makes Moment and
    Datatables.net play nice with each other when sorting columns.
*/
export const renderDate = (data, type) => {
  let newDate = new Date(data);
  let date = type === "sort" || type === "type" ? newDate.getTime() : data;

  return date;
};

// Sort AND display past (overdue) dates as red.
export const renderHistoricalDate = (data, type) => {
  if (!data) return null;
  if (!data.includes("span")) {
    return renderDate(data, type);
  }
  const placeholder = document.createElement("div");
  placeholder.innerHTML = data;
  const innerText = placeholder.innerText;

  // If I refactor this to user the renderDate function, it doesn't return
  // red (overdue) values. Need to keep this
  let newDate = new Date(innerText);
  let date = type === "sort" || type === "type" ? newDate.getTime() : data;

  return date;
};

/*
  This function is to be used as a quick way to display common dates within
  indices, shows and other views, while rendering blank instead of "Invalid Date".
*/
export const setDate = (data) => {
  if (!data) { return null; }

  const date = moment(data).format('MM/DD/Y');
  if (date === 'Invalid date') return null;

  return date;
};

export const setTime = (data) => {
  const date = moment(data).calendar(null,
    { sameElse: 'MM/DD/YYYY LTS' }
  );

  if (date === "Invalid date") return null;

  return date;
};

export const setISODate = (data) => {
  const date = moment(data).format("Y-MM-DD");

  if (date === "Invalid date") return null;

  return date;
};

export const setISODateTime = (data, options) => {
  const date = moment(data).toISOString(true);

  if (date === "Invalid date") return null;

  if (false === options.tz) {
    return date.substr(0, 19);
  }

  return date;
};

export const isoToday = () => {
  return setISODate(new Date());
};

export const getDayOfWeek = (number) => {
  switch (number) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default:
      return null;
  }
};

export const isOneWeekOld = (date) => {
  date = moment(date);

  const oneWeekAgo = moment().add(-7, "days");

  return date <= oneWeekAgo;
};

export const isOlderThanToday = (date) => {
  date = moment(date);
  const today = moment();

  return today > date;
};

export const withoutTimezone = date => {
  return date.replace(/\s*\w{3,}\s*$/, '');
};

export const extractTimezone = date => {
  return date.match(/\s*([\w+-]{3,})\s*$/, '')?.[1];
};

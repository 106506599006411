import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { DateTime } from 'luxon';
import FlightOptions from './flightOptions';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import DataState from '../../DataState';
import ClientContext from '../../../contexts/ClientContext';
import ElasticSearch from '../../../elements/ElasticSearch';
import { ErrorsFor } from '../../../elements/inputs';

import { CREATE_USER_FLIGHT, AIRLINES } from './queries';

const NewFlight = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState();
  const [airlineOptions, setAirlineOptions] = useState(true);
  const [tailNumberOptions, setTailNumberOptions] = useState(false);
  const [showFlightOptions, setShowFlightOptions] = useState(null);
  const [userErrors, setUserErrors] = useState(null);
  const {
    register, handleSubmit, control, formState: { errors }
  } = useForm();
  const { currentUser } = useContext(ClientContext);

  const [createUserFlight] = useMutation(CREATE_USER_FLIGHT, {
    onCompleted: data => {
      console.log(data);
      if (data.createUserFlight.userFlight) {
        navigate(
          `/journeys/${currentUser.username}/flights/${data.createUserFlight.userFlight.id}/edit`,
          {
            state: {
              cannotLookupReason: data.createUserFlight.cannotLookupReason
            }
          }
        );
      } else if (data.createUserFlight.flightOptions.length > 0) {
        setShowFlightOptions(data.createUserFlight);
        setSubmitting(false);
      } else {
        setUserErrors(data.createUserFlight.errors);
        setSubmitting(false);
      }
    }
  });

  if (showFlightOptions) return <FlightOptions data={showFlightOptions} />;

  if (!currentUser) {
    return null
  }
  if (currentUser.username !== username) {
    console.log("USERNAME CHECK")
    navigate(`/journeys/${currentUser.username}/flights/new`);
    return false;
  }

  const onSubmit = data => {
    data.departureAt = DateTime.fromISO(data.departureAt).toISO();
    data.arrivalAt = DateTime.fromISO(data.arrivalAt).toISO();
    if (data.flightNumber) {
      data.flightNumber = parseInt(data.flightNumber);
    }
    if (data.airlineId) {
      data.airlineId = parseInt(data.airlineId);
    }

    createUserFlight({ variables: data });
    setSubmitting(true);
  };

  const handleTypeChange = event => {
    if (event.target.value == "true") {
      setAirlineOptions(true);
      setTailNumberOptions(false);
    } else {
      setAirlineOptions(false);
      setTailNumberOptions(true);
    }
  };

  return (
    <Container>
      {userErrors && (
        <Alert key="danger" variant="danger">
          {userErrors[0].message}
        </Alert>
      )}
      <h2>Add A New Flight</h2>
      <center>
        <div className="form-container">
          <DataState.Wrapper loading={submitting}>
            <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
              <Row>
                <Col>
                  <div id="general-information">
                    Type of Flight:
                    <select
                      type="select"
                      {...register('flightType', {
                        onChange: (e) => {handleTypeChange(e);}
                      })}
                    >
                      <option value="true">Airline Flight (Use flight number)</option>
                      <option value="false">Private Flight (Use tail number)</option>
                    </select>
                  </div>
                </Col>
              </Row>

              { airlineOptions && (
                <Row id="flight-number-options">
                  <Col>
                    Airline
                    <Controller
                      control={control}
                      name="airlineId"
                      render={({ field }) => (
                        <ElasticSearch
                          type="Airline"
                          onChange={selected => field.onChange(selected?.id)}
                        />
                      )}
                    />
                  </Col>
                  <Col>
                    Flight Number (Numbers Only)
                    <ErrorsFor name={"flightNumber"} errors={errors} />
                    <input
                      type="number"
                      {...register('flightNumber', { required: true })}
                    />
                  </Col>
                </Row>
              )}

              { tailNumberOptions && (
                <Row id="tail-number-options">
                  <Col>
                    Tail Number
                    <ErrorsFor name={"tailNumber"} errors={errors} />
                    <input
                      type="text"
                      {...register('tailNumber', { required: true })}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  Departure Date
                  <ErrorsFor name={"departureDate"} errors={errors} />
                  <input
                    type="date"
                    {...register('departureDate', { required: true })}
                  />
                </Col>
              </Row>

              <input value="Find Flight" type="submit" id="submit-button"  />
            </form>
          </DataState.Wrapper>
        </div>
      </center>
    </Container>
  );
};

export default NewFlight;

import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import ErrorBoundary from './components/ErrorBoundary';
import Menu from './components/Menu';
import Footer from './components/Footer';
import { ClientProvider } from './contexts/ClientContext';
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import ClientContext from './contexts/ClientContext';

const ChooseMenu = () => {
  const { currentUser } = useContext(ClientContext);
  var loc = useLocation();
  let showMenu;
  if (loc.pathname === "/") {
    if (currentUser?.admin === true) {
      return(<Menu />)
    } else {
      return(<></>)
    }
  } else {
    return(<Menu />)
  }
  
}

const App = () => {
  return (
    <ErrorBoundary>
      <Router>
        <ClientProvider>
          <div>
            <Menu />
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
            <Footer />
          </div>
        </ClientProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default App;


import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Controller, useWatch } from 'react-hook-form';
import { DateTime } from 'luxon';

import Review from '../../Reviews/new';
import ElasticSearch from '../../../elements/ElasticSearch';
import UserErrors from '../../../elements/UserErrors';
import { intStringsToValues } from '../../../utils/numberHelpers';

export const prepareDataForSubmit = data => {
  intStringsToValues(data, [
    'nightCount', 'nightsEarned', 'staysEarned', 'pointsEarned', 'pointsUsed'
  ]);

  data.checkInOn = DateTime.fromISO(data.checkInOn).toISO();
  data.checkOutOn = DateTime.fromISO(data.checkOutOn).toISO();

  if (data.custom) {
    data.hotelId = null;
  }

  if (!data.reason) { data.reason = null };
}

const HotelForm = ({ register, control, setValue, userErrors, stay }) => {
  const [hotelOptions, setHotelOptions] = useState(false);
  const [chooseHotel, setChooseHotel] = useState(true);
  const [addRoomNumber, setAddRoomNumber] = useState(true);
  const [hotelType, setHotelType] = useState(true);

  const watch = useWatch({
    control,
    name: ['roomCharge', 'taxCharge', 'incidentalsCharge', 'type']
  });

  const watchType = watch[3];

  const totalCharges = watch.slice(0, 3)
    .map(value => value ? parseFloat(value) : 0.0)
    .reduce((sum, value) => sum + value )

  const handleTypeChange = value => {
    if ('hotel' === value) {
      setHotelOptions(false);
      setChooseHotel(true);
      setValue('custom', false);
      setAddRoomNumber(true);
      setHotelType("Hotel or Bed & Breakfast");
    } else {
      setHotelOptions(true);
      setChooseHotel(false);
      setValue('custom', true);
      setAddRoomNumber(false);
      if ('bnb' === value) {
        setHotelType("Airbnb / VRBO / etc...");
      } else if ('personal' === value) {
        setHotelType("Private Residence");
      } else {
        setHotelType("other type of place you stayed");
      }
    }
  };

  useEffect(() => {
    if (stay) {
      handleTypeChange(stay.type);
    }
  }, [stay]);

  const hideDisplayAddHotelButton = value => {
    if (value === true) {
      setHotelOptions(true);
      setValue('custom', true);
      setChooseHotel(false);
    } else {
      setHotelOptions(false);
      setValue('custom', false);
      setChooseHotel(true);
    }
  }

  return (
    <>
      {userErrors && <UserErrors userErrors={userErrors} />}
        <Row>
          <Col>
            <b><font color="red">NOTE:</font></b> Items with <i class="fas fa-lock fa-super"></i> indicate that they are kept private, and no one else can see them.<br /><br />
          </Col>
        </Row>
        <Row>
          <Col>
            What type of place did you stay at?
            <select
              type="select"
              {...register(
                'type',
                { onChange: e => handleTypeChange(e.target.value) }
              )}
            >
              <option value="hotel">Hotel / Bed & Breakfast</option>
              <option value="bnb">Airbnb / VRBO / etc...</option>
              <option value="personal">Private Residence (details will not be public)</option>
              <option value="other">Camping? Sleep in your car at a rest stop? Add it here!</option>
            </select>

            { chooseHotel && (
              <>
                What Hotel / Bed & Breakfast did you stay at?
                <Controller
                  control={control}
                  name="hotelId"
                  render={({ field }) => (
                    <ElasticSearch
                      type="Hotel"
                      onChange={selected => field.onChange(selected?.id)}
                      defaultSelected={stay?.hotel}
                    />
                  )}
                />

                <div style={{width: "100%", backgroundColor: "#28A9E1", borderRadius: "5px", textAlign: "center", color: "white", padding: "5px", fontWeight: "bold"}} onClick={() => hideDisplayAddHotelButton(true)}>
                  Can't find your hotel? Click here to add it?
                </div><br />
              </>
            )}

            { hotelOptions && (
              <>
                {'hotel' === watchType && (
                  <div style={{width: "100%", backgroundColor: "#28A9E1", borderRadius: "5px", textAlign: "center", color: "white", padding: "5px", fontWeight: "bold"}} onClick={() => hideDisplayAddHotelButton(false)}>
                    Want to pick your hotel or bed & breakfast from a list, click here!
                  </div>
                )}

                Name of your {hotelType} (if the  {hotelType} does not have a name, just leave it blank)
                <input
                  type="text"
                  {...register('hotel.name')}
                />

                Street
                <input
                  type="text"
                  {...register('hotel.street')}
                />

                City
                <input
                  type="text"
                  {...register('hotel.city')}
                />

                State
                <input
                  type="text"
                  {...register('hotel.state')}
                />

                Postal Code
                <input
                  type="text"
                  {...register('hotel.postalCode')}
                />

                Country (2-letter code)
                <input
                  type="text"
                  maxLength="2"
                  {...register('hotel.country')}
                />
              </>
            )}
            {addRoomNumber && (
              <Row>
                <Col xl="12">
                  Room Number
                  <input
                    type="text"
                    {...register('roomNumber')}
                  />
                </Col>
              </Row>
            )}
            {stay?.hotel && (
              <Row>
                <Col xl="4">
                  Rating of this Hotel during this Trip<br />
                  <Review entity={stay.hotel} experience={stay} />
                </Col>
                {stay.hotel.hotelChain && (
                  <Col xl="4">
                    Rating of this Hotel Chain during this Trip<br />
                    <Review entity={stay.hotel.hotelChain} experience={stay} />
                  </Col>
                )}
                {stay.hotel.hotelChain?.loyaltyProgram && (
                  <Col xl="4">
                    Rating of Loyalty Program during this Trip<br />
                    <Review
                      entity={stay.hotel.hotelChain.loyaltyProgram}
                      experience={stay}
                    />
                  </Col>
                )}
              </Row>
            )}

          <div class="arrival-information section-header">
            <h4><i class="fa-regular fa-calendar-days"></i> How Long Were You There?</h4>
          </div>
           <Row className="arrival-information">
              <Col xl="6">

                Check In On:
                <input
                  type="date"
                  {...register('checkInOn')}
                />
              </Col>
              <Col>
                Check Out On:
                <input
                  type="date"
                  {...register('checkOutOn')}
                />
              </Col>
            </Row>
            <div class="general-information section-header">
                <h4><i class="fas fa-info-circle"></i> General Information</h4>
              </div>
              <Row className="general-information">
                <Col>
                 Reason For Your Trip
                  <select
                    style={{border: "2px solid black"}}
                    {...register('reason')}>
                    <option value="">Click to Select</option>
                    <option value="vacation">Vacation</option>
                    <option value="business">Business</option>
                    <option value="crew">Crew Travel</option>
                    <option value="other">Other</option>
                  </select>
                </Col>
              </Row>
            <Row className="general-information">
              <Col>
               Personal Notes<i class="fas fa-lock fa-super"></i>
                <textarea {...register('note')}></textarea>
              </Col>
            </Row>
            <div class="spend-information section-header">
                <h4><i class="fa-solid fa-sack-dollar"></i> Spend Information</h4>
              </div>
            <Row className="spend-information">
              <Col>
                Room Charges<i class="fas fa-lock fa-super"></i>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  {...register('roomCharge')}
                />
              </Col>
              <Col>
                Tax Charges<i class="fas fa-lock fa-super"></i>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  {...register('taxCharge')}
                />
              </Col>
            </Row>
            <Row className="spend-information">
              <Col>
                Incidental Charges<i class="fas fa-lock fa-super"></i>
                <input
                  type="number"
                  step="0.01"
                  {...register('incidentalsCharge')}
                />
              </Col>
              <Col>
                Total Paid<i class="fas fa-lock fa-super"></i><br />
                <div style={{fontSize: '1.2em', marginTop: 8}}>
                  {totalCharges.toFixed(2)}
                </div>
              </Col>
            </Row>

            <div class="points-information section-header">
              <h4><i class="fa-solid fa-hundred-points"></i> Rewards Information</h4>
            </div>
            <Row className="points-information">
              <Col>
                Nights Earned<i class="fas fa-lock fa-super"></i>
                <input type="number" {...register('nightsEarned')} />
              </Col>
              <Col>
                Stays Earned<i class="fas fa-lock fa-super"></i>
                <input type="number" {...register('staysEarned')} />
              </Col>
            </Row>
            <Row className="points-information">
              <Col>
                Points Earned<i class="fas fa-lock fa-super"></i>
                <input type="number" {...register('pointsEarned')} />
              </Col>
              <Col>
                Points Used<i class="fas fa-lock fa-super"></i>
                <input type="number" {...register('pointsUsed')} />
              </Col>
            </Row>
          </Col>
        </Row>
    </>
  );
};

export default HotelForm;

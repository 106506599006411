export const WITH_COUNT_VARIABLES = `
  $offset: Int
  $limit: Int
  $sort: String
  $sortDirection: String
  $search: String
  $scopes: [String!]
`;

export const WITH_COUNT_ARGUMENTS = `
  offset: $offset
  limit: $limit
  sort: $sort
  sortDirection: $sortDirection
  search: $search
  scopes: $scopes
`;

import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { setDate } from '../../../utils/dateHelpers';
import { LineChart, PieChart, ColumnChart } from 'react-chartkick'
import 'chartkick/chart.js'
import { numberWithDelimiter } from '../../../utils/stringHelpers';
import DataState from '../../DataState';
import Pluralize from 'react-pluralize'
import { USER_FLIGHTS } from './queries';

const SmallCharts = (props) => {
  const data = props.data;

  var dayGraphData = []
  var count = 0
  data?.flightStats.graphPoints.forEach(point => {
    if (point.dow === true) {
      var key = point.key
      dayGraphData[count] = [key, point.flights]
      count = count + 1;
    }
  })

  var monthGraphData = []
  var count = 0
  data?.flightStats.graphPoints.forEach(point => {
    if (point.month === true) {
      var key = point.key
      monthGraphData[count] = [key, point.flights]
      count = count + 1;
    }
  })

  var airlineGraphData = []
  var count = 0
  data?.flightStats.graphPoints.forEach(point => {
    if (point.airline === true) {
      var key = point.key
      airlineGraphData[count] = [key, point.flights]
      count = count + 1;
    }
  })

  var planeGraphData = []
  var count = 0
  data?.flightStats.graphPoints.forEach(point => {
    if (point.plane === true) {
      var key = point.key
      planeGraphData[count] = [key, point.flights]
      count = count + 1;
    }
  })

  return (
     <>
       <Row style={{padding: "10px"}}>
        <Col>
          <div class="flight-stats-box-dk-blue">
            <span class="flight-stats-header-dk-blue">
              Flights by Day
            </span><br />
            <ColumnChart data={dayGraphData} colors={["#044F94"]} height={"200px"} />
          </div>
        </Col>
        <Col>
          <div class="flight-stats-box-lt-blue">
            <span class="flight-stats-header-lt-blue">
              Flights by Month
            </span><br />
            <ColumnChart data={monthGraphData} colors={["#28A9E1"]} height={"200px"} />
          </div>
        </Col>
        <Col>
          <div class="flight-stats-box-green">
            <span class="flight-stats-header-green">
              Flights by Airline
            </span><br />
            <ColumnChart data={airlineGraphData} colors={["#AED47B"]} height={"200px"} />
          </div>
        </Col>
        <Col>
          <div class="flight-stats-box-red">
            <span class="flight-stats-header-red">
              Most Common Aircraft Types
            </span><br />
            <ColumnChart data={planeGraphData} colors={["#EF4237"]} height={"200px"} />
          </div>
        </Col>
      </Row>
    </>
  )

}

const YearLineChart = (props) => {
  const data = props.data;
  var yearGraphData = {}
  data?.flightStats.flightYears.forEach(year => {
    var yearKey = year.year
    yearGraphData[yearKey] = year.flights
  })
  
  return (
     <>
      <Row style={{padding:"10px"}}>
        <Col>
          <div class="flight-stats-box-dk-blue">
            <span class="flight-stats-header-dk-blue">
              Flights by Year
            </span><br />
            <LineChart data={yearGraphData} curve={false} colors={["#044F94"]} height={"200px"} />
          </div>
        </Col>
      </Row>
    </>
  )
}

const StatBoxes = (props) => {
  const data = props.data;
  
  
  return (
     <>
      <Row style={{padding:"10px"}}>
        <div class="col-lg-3 flight-stats" id="flight-stats-flights">
          <span class="flight-stats-lg"><i class="fad fa-plane"></i> {numberWithDelimiter(data.flightStats.flights)} <Pluralize singular={'Flight'} showCount={false} count={data.flightStats.flights}/></span><br />
          <i class="fad fa-route"></i> {numberWithDelimiter(data.flightStats.uniqueRoutes)} Unique <Pluralize singular={'Route'} showCount={false} count={data.flightStats.uniqueRoutes}/>
        </div>
        <div class="col-lg-3 flight-stats" id="flight-stats-miles">
          <span class="flight-stats-lg"><i class="fad fa-globe-americas"></i> {numberWithDelimiter(data.flightStats.miles)} <Pluralize singular={'Mile'} showCount={false} count={data.flightStats.miles}/></span><br />
          <i class="fad fa-hourglass-half"></i> 
          {data.flightStats.time}
        </div>
        <div class="col-lg-3 flight-stats" id="flight-stats-airlines">
          <span class="flight-stats-lg"><i class="fad fa-plane-departure"></i> {numberWithDelimiter(data.flightStats.airlines)} <Pluralize singular={'Airline'} showCount={false} count={data.flightStats.airlines}/></span><br />
          <i class="fad fa-paper-plane"></i> {numberWithDelimiter(data.flightStats.operators)} Flight <Pluralize singular={'Operator'} showCount={false} count={data.flightStats.operators}/>
        </div>
        <div class="col-lg-3 flight-stats" id="flight-stats-airports">
           <span class="flight-stats-lg"><i class="fad fa-map-marked"></i> {numberWithDelimiter(data.flightStats.airports)} <Pluralize singular={'Airport'} showCount={false} count={data.flightStats.airports}/></span><br />
          <i class="fad fa-passport"></i> {numberWithDelimiter(data.flightStats.countries)} <Pluralize singular={'Country'} plural={'Countries'} showCount={false} count={data.flightStats.countries}/>
        </div>
      </Row>
    </>
  )
}

const FlightStats = (props) => {
  const data = props.data;
  const queryStatus = props.queryStatus;
  const username = props.username;

  return (
    <DataState.Wrapper data={data} {...queryStatus}>
      {data?.flightStats.flightYears?.length > 0 &&
        <YearLineChart data={data} />
      }
      <StatBoxes data={data} {...queryStatus} />
      <SmallCharts data={data} {...queryStatus} />
    </DataState.Wrapper>
  );
};

export default FlightStats;


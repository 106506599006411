import React, { useContext } from 'react';
import DataTable from '@preflighttech/preflight-tables';
import { useNavigate, Link } from 'react-router-dom';
import ClientContext from '../../../contexts/ClientContext';

import { HOTEL_CHAINS } from './queries';

const columns = [
  {
    key: 'id',
    label: 'ID'
  },
  {
    key: 'loyaltyProgram',
    label: 'Loyalty Program',
    content: ({ entry }) => {
      return <>{entry.loyaltyProgram?.name}</>
    }
  },
  {
    key: 'name',
    label: 'Name',
    sort: 'asc'
  },
  {
    key: 'hotels',
    label: 'Hotels',
    content: ({entry}) => {
      return <>{entry.length}</>
    }
  }, 
  {
    key: 'url',
    label: 'URL'
  },
  {
    key: 'logoUri',
    label: 'Logo',
    content: ({ value }) => (
      value && <img src={value} alt="Logo" style={{maxHeight: 100}} />
    ),
  },
  {
    key: 'marker',
    label: 'Marker',
    content: ({ value }) => (
      value && <img src={value} alt="Marker" style={{maxHeight: 100}} />
    ),
  },
  {
    key: 'actions',
    label: 'Actions',
    content: ({ entry }) => (
      <Link to={`/admin/hotelChains/${entry.id}/edit`}>Edit</Link>
    )
  }
];

const AdminHotelChains = () => {
  const { currentUser } = useContext(ClientContext);
  const navigate = useNavigate();

  if (!currentUser) {
    return null
  }
  if (currentUser?.admin !== true) {
    navigate('/');
    return false;
  }

  return (
    <>
      <a href="/admin/hotelChains/new">Add a New Hotel Chain</a>
      <DataTable.Gql
        query={HOTEL_CHAINS}
        queryName="hotelChainsWithCount"
        columns={columns}
        htmlTable
        pageLength={25}
        lengthMenu={ [10, 25, 50, 'All'] }
      />
    </>
  );
};

export default AdminHotelChains;

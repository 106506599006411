import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";

import Review from '../../Reviews/new';
import ElasticSearch from '../../../elements/ElasticSearch';
import { setISODateTime } from '../../../utils/dateHelpers';
import { booleanToString } from '../../../utils/stringHelpers';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiAutocomplete-listbox": {
        minHeight: 400,
        color: "black",
        fontSize: 14,
        padding: "none",
        "& :hover": {
          color: "brown"
        },
        "& li": {
          //list item specific styling
        }
      }
    },
    filter: {
      "&.MuiAutocomplete-root": {
        marginBottom: "15px",
      },
    },
    textfield: {
      "& .MuiOutlinedInput-root": {
        padding: "5px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        borderRadius: "0px",
        margin: "0px"
      },
      "& .MuiInputBase-input.MuiAutocomplete-input": {
        color: "black",
        fontSize: 14,
        padding: "5px",
        margin: "0px",
        border: "none"
      },
      "& #custom-autocomplete-label": {
        //or could be targeted through a class
        color: "red"
      },
      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
        color: "red"
      }
    }
  })
);

export const CustomPopper = function (props) {
  const classes = useStyles();
  return <Popper {...props} className={classes.root} placement="bottom" />;
};

const EditForm = (props) => {
  const onSubmit = props.onSubmit;
  const userFlight = props.userFlight
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      id: userFlight.id,
      flightNumber: userFlight.flightNumber,
      airlineId: userFlight.airline?.id,
      operatorId: userFlight.operator?.id,
      departureAt: setISODateTime(
        userFlight.departureAt || new Date(), { tz: false }
      ),
      departureGate: userFlight.departureGate,
      departureTerminal: userFlight.departureTerminal,
      originId: userFlight.origin?.id,
      destinationId: userFlight.destination?.id,
      arrivalAt: setISODateTime(
        userFlight.arrivalAt || new Date(), { tz: false }
      ),
      arrivalGate: userFlight.arrivalGate,
      arrivalTerminal: userFlight.arrivalTerminal,
      bagClaim: userFlight.bagClaim,
      bagCount: userFlight.bagCount,
      tailNumber: userFlight.tailNumber,
      cabin: userFlight.cabin,
      seatNumber: userFlight.seatNumber,
      exitRow: booleanToString(userFlight.exitRow),
      bulkhead: booleanToString(userFlight.bulkhead),
      seatType: userFlight.seatType,
      reason: userFlight.reason,
      notes: userFlight.notes,
      pnr: userFlight.pnr,
      ticketNumber: userFlight.ticketNumber,
      fareClass: userFlight.fareClass,
      fare: userFlight.fare,
      taxes: userFlight.taxes,
      qualifyingMilesEarned: userFlight.qualifyingMilesEarned,
      milesEarned: userFlight.milesEarned,
      spendEarned: userFlight.spendEarned,
      segmentsEarned: userFlight.segmentsEarned,
    }
  });

  return (
        <div className="form-container">
          <b><font color="red">NOTE:</font></b> Items with <i class="fas fa-lock fa-super"></i> indicate that they are kept private, and no one else can see them.<br /><br />
            <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
              <Row>
                <Col>
                  Airline
                  <Controller
                    control={control}
                    name="airlineId"
                    render={({ field }) => (
                      <ElasticSearch
                        type="Airline"
                        onChange={selected => {
                          field.onChange(selected?.id || null);
                        }}
                        defaultSelected={userFlight.airline}
                      />
                    )}
                  />
                </Col>
                <Col>
                  Operated By
                  <Controller
                    control={control}
                    name="operatorId"
                    render={({ field }) => (
                      <ElasticSearch
                        type="Airline"
                        onChange={selected => {
                          field.onChange(selected?.id || null);
                        }}
                        defaultSelected={userFlight.operator}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row id="general-information">
                <Col>
                  Flight Number (Numbers Only)
                  <input
                    type="number"
                    className="general-information"
                    {...register('flightNumber')}
                  />
                </Col>
                <Col>
                  <b><font color="red">NOTE:</font></b> 
                  The "Operated By" field will only auto populate if your 
                  flight is a codeshare flight and we can determine that 
                  it was operated by another airline.
                </Col>
              </Row>
              <div class="departure-information section-header">
                <h4><i class="fas fa-plane-departure"></i> Departure</h4>
              </div>
              <Row className="departure-information">
                <Col>
                  Departure Airport
                  <Controller
                    control={control}
                    name="originId"
                    render={({ field }) => (
                      <ElasticSearch
                        type="Airport"
                        onChange={selected => {
                          field.onChange(selected?.id || null);
                        }}
                        defaultSelected={userFlight.origin}
                      />
                    )}
                  />
                </Col>
                <Col>
                  Rating of this Airport during this Trip<br />
                  <Review entity={userFlight.origin} experience={userFlight} />
                </Col>
              </Row>
              <Row className="departure-information">
                <Col xl="6">
                  Departure Time
                  <input
                    type="datetime-local"
                    step="1"
                    {...register('departureAt')}
                  />
                </Col>
                <Col>
                  Departure Terminal
                  <input
                    type="text"
                    {...register('departureTerminal')}
                  />
                </Col>
                <Col>
                  Departure Gate
                  <input
                    type="text"
                    {...register('departureGate')}
                  />
                </Col>
              </Row>

              <div class="arrival-information section-header">
                <h4><i class="fas fa-plane-arrival"></i> Arrival</h4>
              </div>
              <Row className="arrival-information">
                <Col>
                  Arrival Airport
                  <Controller
                    control={control}
                    name="destinationId"
                    render={({ field }) => (
                      <ElasticSearch
                        type="Airport"
                        onChange={selected => {
                          field.onChange(selected?.id || null);
                        }}
                        defaultSelected={userFlight.destination}
                      />
                    )}
                  />
                </Col>
                <Col>
                  Rating of this Airport during this Trip<br />
                  <Review
                    entity={userFlight.destination}
                    experience={userFlight}
                  />
                </Col>
              </Row>
              <Row className="arrival-information">
                <Col xl="6">
                  Arrival Time
                  <input
                    type="datetime-local"
                    step="1"
                    {...register('arrivalAt')}
                  />
                </Col>
                <Col>
                  Arrival Terminal
                  <input
                    type="text"
                    {...register('arrivalTerminal')}
                  />
                </Col>
                <Col>
                  Arrival Gate
                  <input
                    type="text"
                    {...register('arrivalGate')}
                  />
                </Col>
              </Row>

              <div class="baggage-information section-header">
                <h4><i class="fas fa-suitcase"></i> Luggage</h4>
              </div>
              <Row className="baggage-information">
                <Col>
                  How many bags did you take?
                  <input
                    type="number"
                    {...register('bagCount')}
                  />
                </Col>
                <Col>
                  Bag Claim
                  <input
                    type="text"
                    {...register('bagClaim')}
                  />
                </Col>
              </Row>

              <div class="airplane-information section-header">
                <h4><i class="fas fa-plane"></i> Airplane</h4>
              </div>
              <Row className="airplane-information">
                <Col>
                 Aircraft Type
                  <Controller
                    control={control}
                    name="planeTypeId"
                    render={({ field }) => (
                      <ElasticSearch
                        type="PlaneType"
                        onChange={selected => {
                          field.onChange(selected?.id || null);
                        }}
                        defaultSelected={userFlight.planeType}
                      />
                    )}
                  />
                </Col>
                <Col>
                  Tail Number
                  <input
                    type="text"
                    {...register('tailNumber')}
                  />
                </Col>
              </Row>
              <Row className="airplane-information">
                <Col>
                  Seat Number
                  <input
                    type="text"
                    {...register('seatNumber')}
                  />
                </Col>
                <Col>
                  Cabin
                  <select
                    {...register('cabin')}>
                    <option value="">Please Select An Option...</option>
                    <option value="first">First Class</option>
                    <option value="business">Business Class</option>
                    <option value="eplus">Economy Plus</option>
                    <option value="coach">Economy</option>
                    <option value="jumpseat">Jumpseat</option>
                    <option value="cockpit">Cockpit</option>
                  </select>
                </Col>
              </Row>
              <Row className="airplane-information">
                <Col xl="6">
                  Seat Location
                  <select
                    {...register('seatType')}>
                    <option value="">Please Select An Option...</option>
                    <option value="window">Window</option>
                    <option value="middle">Middle</option>
                    <option value="aisle">Aisle</option>
                  </select>
                </Col>
                <Col xl="3">
                  Bulkhead?
                  <select
                    {...register('bulkhead')}>
                    <option value="">Please Select An Option...</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </Col>
                <Col xl="3">
                  Exit Row?
                  <select
                    {...register('exitRow')}>
                    <option value="">Please Select An Option...</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </Col>
              </Row>  

              <div class="general-information section-header">
                <h4><i class="fas fa-info-circle"></i> General Information</h4>
              </div>
              <Row className="general-information">
                <Col>
                 Reason For Your Trip
                  <select
                    {...register('reason')}>
                    <option value="">Click to Select</option>
                    <option value="leisure">Leisure</option>
                    <option value="business">Business</option>
                    <option value="crew">Flight Crew</option>
                    <option value="other">Other</option>
                  </select>
                </Col>
              </Row>
              <Row className="general-information">
                <Col>
                 Personal Notes<i class="fas fa-lock fa-super"></i>
                  <textarea {...register('note')}></textarea>
                </Col>
              </Row>
              <Row className="general-information">
                <Col xl="3">
                 Confirmation Number<i class="fas fa-lock fa-super"></i>
                  <input type="text" {...register('pnr')} />
                </Col>
                <Col xl="3">
                 Fare Class (i.e. "F", "Y", "PN")<i class="fas fa-lock fa-super"></i>
                  <input type="text" {...register('fareClass')} />
                </Col>
                <Col xl="6">
                  E-Ticket Number<i class="fas fa-lock fa-super"></i>
                  <input type="text" {...register('ticketNumber')} />
                </Col>
              </Row>

              <div class="spend-information section-header">
                <h4><i class="fa-solid fa-sack-dollar"></i> Spend Information</h4>
              </div>
              <Row className="spend-information">
                <Col>
                  Fare Paid<i class="fas fa-lock fa-super"></i>
                  <input type="number" {...register('fare')} />
                </Col>
                <Col>
                  Taxes Paid<i class="fas fa-lock fa-super"></i>
                  <input type="number" {...register('taxes')} />
                </Col>
              </Row>

              <div class="points-information section-header">
                <h4><i class="fa-solid fa-hundred-points"></i> Rewards Information</h4>
              </div>
              <Row className="points-information">
                <Col>
                  Qualifying Spend Earned (PQP/MQD/etc...)<i class="fas fa-lock fa-super"></i>
                  <input type="number" {...register('spendEarned')} />
                </Col>
                <Col>
                  Qualifying Segments Earned (MQS/PQS/etc...)<i class="fas fa-lock fa-super"></i>
                  <input type="number" {...register('segmentsEarned')} />
                </Col>
              </Row>
              <Row className="points-information">
                <Col>
                  Qualifying Miles Earned (MQM/PQM/etc...)<i class="fas fa-lock fa-super"></i>
                  <input type="number" {...register('qualifyingMilesEarned')} />
                </Col>
                <Col>
                  Award Miles/Points Earned<i class="fas fa-lock fa-super"></i>
                  <input type="number" {...register('milesEarned')} />
                </Col>
              </Row>

              <input value="Update Flight" type="submit" id="submit-button"  />
            </form>
        </div>
  );
};

export default EditForm;

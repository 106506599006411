import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';

import DataState from '../../DataState';

import Form from './form';
import { CREATE_AIRLINE } from './queries';

const NewAirline = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState();
  const [userErrors, setUserErrors] = useState();
  const { register, control, handleSubmit } = useForm();

  const [createAirline, { error }] = useMutation(CREATE_AIRLINE, {
    onCompleted: data => {
      if (data.createAirline.airline) {
        navigate('/admin/airlines');
      } else {
        setUserErrors(data.createAirline.errors);
        setSubmitting(false);
      }
    }
  });

  const onSubmit = data => {
    createAirline({ variables: data });
    setSubmitting(true);
  };

  return (
    <Container>
      <h2>Add A New Airline</h2>
      <center>
        <div className="form-container">
          <DataState.Wrapper loading={submitting}>
            <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
              <Form
                register={register}
                control={control}
                userErrors={userErrors}
              />

              <input value="Add Airline" type="submit" id="submit-button" />
            </form>
          </DataState.Wrapper>
        </div>
      </center>
    </Container>
  );
};

export default NewAirline;

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useSearchParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { setDate } from '../../../utils/dateHelpers';
import FlightsTable from './table';
import FlightMap from './map';
import FlightStats from './stats';
import DataState from '../../DataState';

import { USER_FLIGHTS } from './queries';
import Filter from './Filter';

const Flights = () => {
  const { username, ...filterParams } = useParams();
  const [sort, setSort] = useState('departureAt');
  const [sortDirection, setSortDirection] = useState('desc');

  const [searchParams] = useSearchParams();

  searchParams.forEach((value, key) => {
    if (!filterParams[key]) {
      filterParams[key] = value;
    }
  });

  const [filters, setFilters] = useState(filterParams);

  const queryFilters = {
    airline: filters.airline,
    flightNumber: filters.flightNumber,
    operator: filters.operator,
    airport: filters.airport,
    pairAirport: filters.pairAirport,
    aircraft: filters.aircraft,
    tail: filters.tail,
    year: (filters.year ? parseInt(filters.year) : null),
  }

  const { data, ...queryStatus } = useQuery(USER_FLIGHTS, {
    variables: {
      username,
      filters: queryFilters,
      noActualTrack: true,
      sort,
      sortDirection,
    }
  });

  const toggleSortDirection = () => {
    if ('asc' === sortDirection) {
      setSortDirection('desc');
    } else {
      setSortDirection('asc');
    }
  };

  return (
    <>
      <DataState.Wrapper data={data} {...queryStatus}>
        <h1>{data?.userByUsername?.displayName}'s Flights</h1>
        {data?.userFlights.length == 0 && (
          <Row>
            <Col>
              {data?.userByUsername?.displayName} has no flights.
            </Col>
          </Row>
        )}
        {data?.userFlights.length > 0 && (
          <FlightMap
            data={data}
            queryStatus={queryStatus}
            username={username}
            displayActualTrack={false}
            displayDirectTrack={true}
          />
        )}
        <Filter username={username} filters={filters} setFilters={setFilters} />
        {data?.userFlights.length > 0 && (
          <>
            <FlightStats
              data={data}
              queryStatus={queryStatus}
              username={username}
            />

            <div style={{marginBottom: 6, marginLeft: 6}}>
              Sort By:{' '}
              <select value={sort} onChange={e => setSort(e.target.value)}>
                <option value="airline">Airline</option>
                <option value="operator">Operator</option>
                <option value="departureAt">Departure Date/Time</option>
                <option value="arrivalAt">Arrival Date/Time</option>
              </select>

              <span onClick={toggleSortDirection}>
                {'asc' === sortDirection ? ' ▲' : ' ▼'}
              </span>
            </div>

            <FlightsTable
              data={data}
              queryStatus={queryStatus}
              username={username}
            />
          </>
        )}
      </DataState.Wrapper>
    </>
  );
};

export default Flights;

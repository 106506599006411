import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Controller } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';

import ClientContext from '../../contexts/ClientContext';
import UserErrors from '../../elements/UserErrors';
import { ErrorsFor } from '../../elements/inputs';
import DataState from '../DataState';

import { USER_FILE_TYPES } from './queries';

const UserFileForm = props => {
  const { register, control, resourceType, errors, watch, userFile } = props;
  const { currentUser } = useContext(ClientContext);
  const { data: typesData } = useQuery(USER_FILE_TYPES);

  if (!typesData || !currentUser) { return <DataState.Loading />; }

  const types = typesData.userFileTypes.filter(type => {
    if (!currentUser?.activeSubscription && type.premium) {
      return false;
    }

    return type.on.includes(resourceType);
  });

  const selectedType = types.find(type => watch('type') === type.key);

  return (
    <Row>
      <Col>
        Name
        <ErrorsFor name="name" errors={errors} />
        <input
          type="text"
          {...register('name', { required: true })}
        />

        Type
        <ErrorsFor name="type" errors={errors} />
        <select
          type="select"
          {...register('type', { required: true })}
        >
          <option value="" disabled>Please choose</option>
          {types.map(type => (
            <option key={type.key} value={type.key}>{type.label}</option>
          ))}
        </select>

        {currentUser.activeSubscription && (
          <div>
            Private
            {selectedType?.alwaysPrivate ? (
              <div style={{marginBottom: 10, fontStyle: 'italic'}}>
                {selectedType.label} files are always private.
              </div>
            ) : (<Controller
                control={control}
                name="visibility"
                render={({ field: { value, onChange }}) => (
                  <input
                    style={{width: '2em'}}
                    type="checkbox"
                    checked={value === 'Private'}
                    onChange={e => (
                      onChange(e.target.checked ? 'Private' : 'Public')
                    )}
                  />
                )}
              />
            )}
          </div>
        )}

        {userFile ? (
          <img
            src={userFile.url}
            alt={userFile.name}
            style={{maxWidth: '100%', maxHeight: 200}}
          />
        ) : (
          <>
            File
            <ErrorsFor name="file" errors={errors} />
            <input
              type="file"
              required
              {...register('file', { required: true })}
            />
          </>
        )}
      </Col>
    </Row>
  );
};

export default UserFileForm;

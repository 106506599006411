import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import DataState from '../DataState';
import ClientContext from '../../contexts/ClientContext';

import { CONFIRM_REGISTRATION } from './queries';

const Confirm = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('confirmationToken');
  const { updateCredentials } = useContext(ClientContext);

  const [confirmRegistration, { error }] = useMutation(CONFIRM_REGISTRATION, {
    onCompleted: data => {
      updateCredentials(data.userConfirmRegistrationWithToken.credentials);
      navigate('/');
    }
  });

  useEffect(() => {
    if (!token || !token.length) {
      navigate('/');
    } else {
      confirmRegistration({ variables: { confirmationToken: token } });
    }
  }, []);

  if (error) {
    return (
      <span>{error.message}</span>
    );
  }

  return (
    <DataState.Wrapper loading />
  );
};

export default Confirm;

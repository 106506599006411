import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

import DataState from '../../DataState';
import ClientContext from '../../../contexts/ClientContext';

import Form, { prepareDataForSubmit } from './form';
import { CREATE_STAY } from './queries';

const NewStay = () => {
  const { username } = useParams();
  const { currentUser } = useContext(ClientContext);
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState();

  const [userErrors, setUserErrors] = useState(null);
  const {
    register, handleSubmit, control, setValue, formState: { errors }
  } = useForm({ defaultValues: { type: 'hotel', custom: false } });

  const [createStay, { error }] = useMutation(CREATE_STAY, {
    onCompleted: data => {
      navigate(`/journeys/${currentUser.username}/stays`);
    }
  });

  if (!currentUser) {
    return null;
  } else if (currentUser?.username !== username) {
    navigate(`/journeys/${currentUser.username}/stays/new`);
    return null;
  }

  const onSubmit = data => {
    prepareDataForSubmit(data);
    createStay({ variables: data });
    setSubmitting(true);
  };

  return (
    <Container>
      {userErrors && (
        <Alert key="danger" variant="danger">
          {userErrors[0].message}
        </Alert>
      )}
      <h2>Add A New Stay</h2>
      <center>
        <div className="form-container">
          <DataState.Wrapper loading={submitting}>
            <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
               <Form
                register={register}
                control={control}
                userErrors={userErrors}
                setValue={setValue}
              />

              <input value="Add Stay" type="submit" id="submit-button" />
            </form>
          </DataState.Wrapper>
        </div>
      </center>
    </Container>
  );
};

export default NewStay;

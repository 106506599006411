import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FlightMap from './map';
import DataState from '../../DataState';
import parse from 'html-react-parser';

import UserFiles from '../../UserFiles';
import {numberWithDelimiter} from '../../../utils/stringHelpers';
import ClientContext from '../../../contexts/ClientContext';

import { USER_FLIGHT } from './queries';

const Flight = () => {
  const { username, id } = useParams();

  const { data, loading, error, refetch, ...queryStatus } = useQuery(
    USER_FLIGHT, { variables: { id } }
  );

  const { currentUser } = useContext(ClientContext);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const flight = data.userFlight;

  return (
    <>
      <DataState.Wrapper data={data} {...queryStatus}>
        <h2>{flight.ident} | {flight.origin.iata} - {flight.destination.iata}</h2>
        <FlightMap 
          data={data} 
          queryStatus={queryStatus} 
          username={username} 
          displayActualTrack={true}
          displayDirectTrack={true}
        />
        <Row>
          <Col style={{padding: "10px", marginLeft: "10px"}}>
            { flight.previousFlightId && 
                <>
                  <a href={`/journeys/${username}/flights/${flight.previousFlightId}`}>{parse(flight.previousFlightLinkText)}</a>
                </>
            }
          </Col>
          <Col style={{textAlign: "right", padding: "10px", marginRight: "10px"}}>
            { flight.nextFlightId && 
                <>
                  <a href={`/journeys/${username}/flights/${flight.nextFlightId}`}>{parse(flight.nextFlightLinkText)}</a>
                </>
            }
          </Col>
        </Row>

        <Row>
          <Col>
            <div style={{backgroundColor: "white", textAlign: "center"}}>
              { flight.airline?.logoUri && 
                <img src={flight.airline.logoUri} alt="Logo" height="100px" />
              }
              { flight.operator?.name &&
                <>
                  <br />Operated By: {flight.operator.name}
                </>
              }
            </div>
            <table border="0" cellpadding="2" cellspacing="2" width="100%" class="detail-table">
              <tr>
                <td class="airport-code" width="45%">{flight.origin?.iata}</td>
                <td class="airport-code"><i class='fas fa-plane'></i></td>
                <td class="airport-code" width="45%">{flight.destination?.iata}</td>
              </tr>
              <tr>
                <td align="center">{flight.origin?.name}</td>
                <td align="center">to</td>
                <td align="center">{flight.destination?.name}</td>
              </tr>
              <tr>
                <td class="airport-code"><i class="fas fa-plane-departure"></i></td>
                <td></td>
                <td class="airport-code"><i class="fas fa-plane-arrival"></i></td>
              </tr>
              <tr>
                <td align="center">{flight.departureTimeString}</td>
                <td align="center"></td>
                <td align="center">{flight.arrivalTimeString}</td>
              </tr>
              <tr>
                <td colspan="3" align="center">Flight Time: {flight.actualFlightTime}</td>
              </tr>
              <tr>
                <td colspan="3" align="center">Distance: {numberWithDelimiter(parseInt(flight.distanceMiles))} Miles ({numberWithDelimiter(flight.distanceKm)} Kilometers)</td>
              </tr>
              <tr>
                <td align="center">Departure Gate: {flight.departureTerminal}{flight.departureGate}</td>
                <td align="center"></td>
                <td align="center">Arrival Gate: {flight.arrivalTerminal}{flight.arrivalGate}</td>
              </tr>
              <tr>
                <td align="center">Checked Bags: {flight.bagCount}</td>
                <td align="center"></td>
                <td align="center">Baggage Claim: {flight.bagClaim}</td>
              </tr>
              {(currentUser.username === username) &&
                <>
                  <tr>
                    <td colspan="2" align="center">Confirmation Number: {flight.pnr}</td>
                    <td align="center">LINK FOR PNR GOES HERE</td>
                  </tr>
                </>
              }
            </table>
          </Col>
          <Col>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9334205495216621"
                 crossorigin="anonymous"></script>
            <ins class="adsbygoogle"
                 style={{display:"block"}}
                 data-ad-client="ca-pub-9334205495216621"
                 data-ad-slot="1738540777"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
            <script>
                 (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
            <table border="0" cellpadding="2" cellspacing="2" width="100%" class="detail-table">
              <tr>
                <td>Flights Between {flight.origin?.iata} and {flight.destination?.iata}:</td>
                <td>{numberWithDelimiter(flight.flightsWithThisCityPair)}</td>
              </tr>
              <tr>
                <td>Aircraft Type for this Flight:</td>
                <td>{flight.planeType?.name} ({flight.planeType?.icao || null}/{flight.planeType?.iata || null})</td>
              </tr>
              <tr>
                <td>Number of flights on this aircraft type:</td>
                <td>{numberWithDelimiter(flight.flightsWithThisPlaneType)}</td>
              </tr>
              <tr>
                <td>The tail number of the airplane used for this flight:</td>
                <td>
                  {flight.tailNumber != null &&
                    <>
                      {flight.tailNumber}
                    </>
                  }
                  {flight.tailNumber == null &&
                    <>
                      Unknown
                    </>
                  }
                </td>
              </tr>
              <tr>
                <td>Number of times you've flown on this airplane:</td>
                <td>{numberWithDelimiter(flight.flightsWithThisPlane)}</td>
              </tr>
              <tr>
                <td>The flight number used for this flight:</td>
                <td>
                  {flight.ident}
                </td>
              </tr>
              <tr>
                <td>Number of times you've flown with this flight number:</td>
                <td>
                  {numberWithDelimiter(flight.flightsWithThisFlightNumber)}
                </td>
              </tr>
              {flight.airline != null &&
                <tr>
                  <td>Total Flights on {flight.airline.name}:</td>
                  <td>{numberWithDelimiter(flight.flightsWithThisAirline)}</td>
                </tr>
              }
              {flight.operator != null &&
                 <tr>
                  <td>Total Flights on {flight.operator.name}:</td>
                  <td>{numberWithDelimiter(flight.flightsWithThisOperator)}</td>
                </tr>
              }
            </table>
            <hr />
            <h2>Files</h2>
            <UserFiles resource={flight} refetch={refetch} />
          </Col>
        </Row>
      </DataState.Wrapper>
    </>
  );
};

export default Flight;

import React from 'react';
import { useQuery } from '@apollo/client';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useStyles } from '../../Journeys/Flights/editForm';

import { HOTEL_CHAINS_SELECT } from './queries';

const HotelChainsSelect = ({ control, name }) => {
  const { data } = useQuery(HOTEL_CHAINS_SELECT);
  const classes = useStyles();

  if (!data) {
    return null;
  }

  const formattedOptions = data.hotelChains;

  const selectedOption = value => {
    if (value) {
      return formattedOptions.find(option => value === option.value);
    } else {
      return null;
    }
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Autocomplete
          disablePortal
          className={classes.filter}
          options={formattedOptions}
          sx={{ width: "100%", border: "2px solid black"}}
          style={{border: "2px solid black"}}
          isOptionEqualToValue={(option, selected) => (
            option.value === selected?.value
          )}
          onChange={(e, selected) => (
            field.onChange(selected?.value || null)
          )}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              className={classes.textfield}
            />
          )}
          value={selectedOption(field.value)}
        />
      )}
    />
  )
};

export default HotelChainsSelect;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DataState from '../../DataState';
import ClientContext from '../../../contexts/ClientContext';
import { setDate } from '../../../utils/dateHelpers';

const StaysTable = ({ data, queryStatus, username }) => {
  const { currentUser } = useContext(ClientContext);
  const isCurrentUser = username === currentUser?.username;

  return (
    <DataState.Wrapper data={data} {...queryStatus}>
      {data?.stays.map(stay => (
        <div style={{border: "1px solid black", borderRadius: "10px", padding: "10px", marginBottom: "5px"}} key={stay.id}>
          <Row>
            <Col lg="4">
              <h5>{stay.hotel?.name}</h5>
              {stay.hotel?.location}
            </Col>
            <Col lg="4">
              <h5>{stay.nightCount}</h5>
              {setDate(stay.checkInOn)} - {setDate(stay.checkOutOn)}
            </Col>
            <Col xl="1">
              <Link to={`/journeys/${username}/stays/${stay.id}`}>View</Link>
              {isCurrentUser && (
                <>
                  {' | '}
                  <Link to={`/journeys/${username}/stays/${stay.id}/edit`}>
                    Edit
                  </Link>
                </>
              )}
            </Col>
          </Row>
        </div>
      ))}
    </DataState.Wrapper>
  );
};

export default StaysTable;

import React, { useContext } from 'react';
import DataTable from '@preflighttech/preflight-tables';
import { useNavigate, Link } from 'react-router-dom';
import ClientContext from '../../../contexts/ClientContext';

import { HOTELS } from './queries';

const columns = [
  {
    key: 'id',
    label: 'ID'
  },
  {
    key: 'name',
    label: 'Name',
    sort: 'asc'
  },
  {
    key: 'actions',
    label: 'Actions',
    sort: 'prevent',
    content: ({ entry }) => (
      <Link to={`/admin/hotels/${entry.id}/edit`}>Edit</Link>
    ),
  },
];

const AdminHotels = () => {
  const { currentUser } = useContext(ClientContext);
  const navigate = useNavigate();

  if (!currentUser) {
    return null
  }
  if (currentUser?.admin !== true) {
    navigate('/');
    return false;
  }

  return (
    <>
      <a href="/admin/hotels/new">Add a New Hotel</a>
      <DataTable.Gql
        query={HOTELS}
        queryName="hotelsWithCount"
        columns={columns}
        htmlTable
        pageLength={25}
        lengthMenu={ [10, 25, 50, 'All'] }
      />
    </>
  );
};

export default AdminHotels;

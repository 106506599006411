import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { setDate } from '../../../utils/dateHelpers';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api'
import { useRef, useState } from 'react'
import DataState from '../../DataState';

const center = { lat: 48.8584, lng: 2.2945 }

const FlightMap = (props) => {
  const data = props.data;
  const queryStatus = props.queryStatus;
  const username = props.username;
  const displayActualTrack = props.displayActualTrack;
  const displayDirectTrack = props.displayDirectTrack;
  const [map, setMap] = useState(/** @type google.maps.Map */ (null))

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAs-wC7nbdawdnHtHJaRStAD3weHiY2nfU"
  })

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef()

  return (
    <DataState.Wrapper data={data} {...queryStatus}>
        <div style={{height: '50vh', width: '100vw'}}>
          <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
              zoomControl: true,
              streetViewControl: false,
              mapTypeControl: true,
              fullscreenControl: true,
            }}
            onLoad={map => {
              const bounds = new window.google.maps.LatLngBounds();
              var path_points = Array();
              var markers = Array();
              var info_windows = Array();

              // Add Markers for Airports
              data.flightsForMap.markers.map(marker => {
                path_points.push(new window.google.maps.LatLng(marker.latitude, marker.longitude));
                var image = {
                  url: marker.image,
                  size: new window.google.maps.Size(30, 52),
                  anchor: new window.google.maps.Point(15, 52)
                };
                
                markers.push(new window.google.maps.Marker({
                  position: path_points[marker.idx],
                  map: map,
                  icon: image
                }));
                var pos = new window.google.maps.LatLng(marker.latitude, marker.longitude);
                bounds.extend(pos);

                info_windows.push(new window.google.maps.InfoWindow({
                  content: marker.infoBox
                }));

                window.google.maps.event.addListener(markers[marker.idx], 'click', function() {
                  info_windows[marker.idx].open(map, markers[marker.idx]);
                });
              });

              // Now let's add the Direct Lines
              if (displayDirectTrack === true) {
                data.flightsForMap.routes.map(route => {
                  if (route.directPath === true) {
                    var flightPath = new window.google.maps.Polyline({path: [new window.google.maps.LatLng(route.origin.latitude, route.origin.longitude),new window.google.maps.LatLng(route.destination.latitude, route.destination.longitude)], strokeColor: route.lineColor, strokeOpacity: 0.7, strokeWeight: 2, geodesic: true });

                    info_windows.push(new window.google.maps.InfoWindow({
                      content: route.infoBox
                    }));

                    window.google.maps.event.addListener(flightPath, 'click', function(event) {
                      if (event) {
                         var point = event.latLng;
                      }
                      info_windows[route.idx].setPosition(point);
                      info_windows[route.idx].open(map);
                    });

                    flightPath.setMap(map);
                  }
                })
              };

              // Now let's add the Actual Tracks
              if (displayActualTrack === true) {
                data.flightsForMap.routes.map(route => {
                  if (route.directPath === false) {

                    var flightPlanCoordinates = route.actualTrackPoints.map(point => {
                      return {lat: parseFloat(point.latitude), lng: parseFloat(point.longitude)}
                    })

                    // console.log(flightPlanCoordinates);

                    //var flightPlanCoordinates = JSON.parse(JSON.stringify(route.actualTrack));
                    var flightPath = new window.google.maps.Polyline({path: flightPlanCoordinates, strokeColor: "#0000ff", strokeOpacity: 0.7, strokeWeight: 2, geodesic: false });

                    info_windows.push(new window.google.maps.InfoWindow({
                      content: route.infoBox
                    }));

                    window.google.maps.event.addListener(flightPath, 'click', function(event) {
                      if (event) {
                         var point = event.latLng;
                      }
                      info_windows[route.idx].setPosition(point);
                      info_windows[route.idx].open(map);
                    });

                    // <% @points.each do |p| %>
                    //   pos = new google.maps.LatLng(<%= p[0] %>, <%= p[1] %>);
                    //   bounds.extend(pos);
                    // <% end %>

                    flightPath.setMap(map);
                  }
                })
              };


              map.fitBounds(bounds);
            }}
          >
          </GoogleMap>
        </div>
    </DataState.Wrapper>
  );
};

export default FlightMap;

/**
  This field is for use in form submisssion - to allow number fields that aren't required to be
  set to null instead of an empty string when updating (empty strings don't jive with GraphQL).
  It is meant as a solution for the following issue:
  https://github.com/jaredpalmer/formik/issues/1869
  There is an example of it being used in the handleSubmit of Auctions/Edit.js if usage is unclear.
  It is here so I don't have to remember what needs done every time we have a non-required number
  field.
*/
export const makeNullable = (numberInput) => {
    numberInput = numberInput === '' ? null : numberInput;
    return numberInput;
}

export const intStringsToValues = (object, properties) => {
  properties.forEach(property => {
    if (!object[property]) {
      object[property] = null;
    } else {
      object[property] = parseInt(object[property]);
    }
  });
};

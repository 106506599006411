import { useContext } from 'react';

import ClientContext from '../../contexts/ClientContext';

const useUserFilePath = () => {
  const { currentUser } = useContext(ClientContext);

  return ({ resourceType, resourceId, suffix }) => {
    if (!currentUser) { return ''; }

    let prefix = '';

    if ('UserFlight' === resourceType) {
      prefix = `/journeys/${currentUser?.username}/flights/${resourceId}`;
    } else if ('Stay' === resourceType) {
      prefix = `/journeys/${currentUser?.username}/stays/${resourceId}`;
    }

    if (suffix) {
      return prefix + '/' + suffix;
    } else {
      return prefix;
    }
  }
};

export default useUserFilePath;

import { gql } from '@apollo/client';

export const USER_FILE_FIELDS = gql`
  fragment UserFileFields on UserFile {
    id
    user { id }
    name
    filename
    type
    visibility
    url
  }
`;

export const USER_FILE = gql`
  ${USER_FILE_FIELDS}
  query UserFile($id: ID!) {
    userFile(id: $id) {
      ...UserFileFields
    }
  }
`;

const USER_FILE_VARIABLES = `
  $name: String!
  $type: String!
  $visibility: String!
`;

const USER_FILE_ARGUMENTS = `
  name: $name
  type: $type
  visibility: $visibility
`;

export const CREATE_USER_FILE = gql`
  ${USER_FILE_FIELDS}
  mutation CreateUserFile(
    ${USER_FILE_VARIABLES}
    $resourceType: String!
    $resourceId: ID!
    $file: Upload!
  ) {
    createUserFile(
      ${USER_FILE_ARGUMENTS}
      resourceType: $resourceType
      resourceId: $resourceId
      file: $file
    ) {
      userFile {
        ...UserFileFields
      }
      errors { path, message }
    }
  }
`;

export const UPDATE_USER_FILE = gql`
  ${USER_FILE_FIELDS}
  mutation UpdateUserFile(
    $id: ID!
    ${USER_FILE_VARIABLES}
  ) {
    updateUserFile(
      id: $id
      ${USER_FILE_ARGUMENTS}
    ) {
      userFile {
        ...UserFileFields
      }
      errors { path, message }
    }
  }
`;

export const DESTROY_USER_FILE = gql`
  mutation DestroyUserFile($id: ID!) {
    destroyUserFile(id: $id) {
      errors { path, message }
    }
  }
`;

export const USER_FILE_TYPES = gql`
  query NoUserLogUserFileTypes {
    userFileTypes {
      key
      label
      on
      premium
      alwaysPrivate
    }
  }
`;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DataState from '../../DataState';
import ClientContext from '../../../contexts/ClientContext';
import { setDate } from '../../../utils/dateHelpers';

import { USER_FLIGHTS } from './queries';

const FlightsTable = ({ data, queryStatus, username }) => {
  const { currentUser } = useContext(ClientContext);
  const isCurrentUser = username === currentUser?.username;

  return (
    <DataState.Wrapper data={data} {...queryStatus}>
      {data?.userFlights.map(userFlight => (
        <div style={{border: "1px solid black", borderRadius: "10px", padding: "10px", marginBottom: "5px"}} key={userFlight.id}>
          <Row>
            <Col lg="4">
              <h5>{userFlight.ident} | {userFlight.origin.iata} - {userFlight.destination.iata}</h5>
              {setDate(userFlight.departureDate)}
            </Col>
            <Col lg="4">
              <h5>{userFlight.airline?.name}</h5>
              {userFlight.operator && (
                <>
                  Operated By: {userFlight.operator.name}
                </>
              )}
            </Col>
            <Col sm>
              <h5>{userFlight.tailNumber}</h5>
              {userFlight.planeType?.name}
            </Col>
            <Col xl="1">
              <h5>{userFlight.seatNumber}</h5>
              {userFlight.cabin}
            </Col>
            <Col xl="1">
              <Link to={`/journeys/${username}/flights/${userFlight.id}`}>
                View
              </Link>
              {isCurrentUser && (
                <>
                  {' | '}
                  <Link to={`/journeys/${username}/flights/${userFlight.id}/edit`}>
                    Edit
                  </Link>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              O: {userFlight.importOrigin} | D: {userFlight.importDestination} | R: {userFlight.importRow}
            </Col>
          </Row>
        </div>
      ))}
    </DataState.Wrapper>
  );
};

export default FlightsTable;

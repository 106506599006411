import { gql } from '@apollo/client';

import { REVIEW_FIELDS } from '../../Reviews/queries';
import { USER_FILE_FIELDS } from '../../UserFiles/queries';

export const USER_FLIGHTS = gql`
  query UserFlights(
    $username: String!
    $filters: UserFlightFilters
    $sort: String
    $sortDirection: String
    $noActualTrack: Boolean
  ) {
    userFlights(
      username: $username
      filters: $filters
      sort: $sort
      sortDirection: $sortDirection
    ) {
      id
      importRow
      importOrigin
      importDestination
      origin { id, icao, iata }
      destination { id, icao, iata }
      airline { id, name, icao, iata }
      operator { id, name }
      planeType { id, name }
      tailNumber
      departureDate
      ident
      seatNumber
      cabin
    }
    userByUsername(username: $username) {
      displayName
    }
    flightStats(
      username: $username
      filters: $filters
    ){
      flights
      uniqueRoutes
      airlines
      operators
      time
      miles
      airports
      countries
      flightYears {
        year
        flights
      }
      graphPoints {
        key
        flights
        dow
        month
        airline
        plane
      }
    }
    flightsForMap(
      username: $username
      filters: $filters
      noActualTrack: $noActualTrack
    ) {
      routes {
        origin {
          latitude
          longitude
        }
        destination {
          latitude
          longitude
        }
        numberOfFlights
        lineColor
        infoBox
        idx
        actualTrackPoints {
          latitude
          longitude
        }
        directPath
      }
      markers {
        image
        infoBox
        latitude
        longitude
        idx
      }
    }
  }
`;

export const USER_FLIGHT = gql`
  ${USER_FILE_FIELDS}
  query UserFlight($id: ID!) {
    userFlight(id: $id) {
      id
      origin { id, name, icao, iata }
      destination { id, name, icao, iata }
      airline { id, name, icao, iata, logoUri }
      operator { id, name, icao, iata }
      planeType { id, name, icao, iata }
      tailNumber
      departureAt
      departureTerminal
      departureGate
      arrivalAt
      arrivalTerminal
      arrivalGate
      ident
      seatNumber
      flightNumber
      cabin
      bagCount
      bagClaim
      seatNumber
      exitRow
      bulkhead
      seatType
      reason
      notes
      ticketNumber
      pnr
      fareClass
      fare
      taxes
      qualifyingMilesEarned
      milesEarned
      spendEarned
      segmentsEarned
      nextFlightId
      nextFlightLinkText
      previousFlightId
      previousFlightLinkText
      departureTimeString
      arrivalTimeString
      actualFlightTime
      distanceMiles
      distanceKm
      flightsWithThisCityPair
      flightsWithThisFlightNumber
      flightsWithThisPlane
      flightsWithThisPlaneType
      flightsWithThisOperator
      flightsWithThisAirline
      flightsWithThisFlightNumber
      user { username }
      userFiles {
        ...UserFileFields
      }
    }
    flightsForMap(
      id: $id
    ) {
      routes {
        origin {
          latitude
          longitude
        }
        destination {
          latitude
          longitude
        }
        numberOfFlights
        lineColor
        infoBox
        idx
        actualTrackPoints {
          latitude
          longitude
        }
        directPath
      }
      markers {
        image
        infoBox
        latitude
        longitude
        idx
      }
    }
  }
`;

export const USER_FLIGHT_FOR_EDIT = gql`
  ${REVIEW_FIELDS}
  query UserFlight($id: ID!) {
    userFlight(id: $id) {
      id
      experienceId
      origin { id, name, icao, iata, entityId }
      destination { id, name, icao, iata, entityId }
      airline { id, name, icao, iata, entityId }
      operator { id, name, icao, iata, entityId }
      planeType { id, name, icao, iata }
      tailNumber
      departureAt
      departureTerminal
      departureGate
      arrivalAt
      arrivalTerminal
      arrivalGate
      ident
      seatNumber
      flightNumber
      cabin
      bagCount
      bagClaim
      seatNumber
      exitRow
      bulkhead
      seatType
      reason
      notes
      ticketNumber
      pnr
      fareClass
      fare
      taxes
      qualifyingMilesEarned
      milesEarned
      spendEarned
      segmentsEarned
      nextFlightId
      nextFlightLinkText
      previousFlightId
      previousFlightLinkText
      reviews {
        ...ReviewFields
      }
    }
    flightsForMap(
      id: $id
    ) {
      routes {
        origin {
          latitude
          longitude
        }
        destination {
          latitude
          longitude
        }
        numberOfFlights
        lineColor
        infoBox
        idx
        actualTrackPoints {
          latitude
          longitude
        }
        directPath
      }
      markers {
        image
        infoBox
        latitude
        longitude
        idx
      }
    }
  }
`;

const USER_FLIGHT_VARIABLES = `
  $flightId: ID
  $departureDate: ISO8601Date
  $airlineId: Int
  $flightNumber: Int
  $tailNumber: String
  $flightawareFlightId: String
`;

const USER_FLIGHT_ARGUMENTS = `
  flightId: $flightId
  departureDate: $departureDate
  tailNumber: $tailNumber
  flightNumber: $flightNumber
  airlineId: $airlineId
  flightawareFlightId: $flightawareFlightId
`;

export const CREATE_USER_FLIGHT = gql`
  mutation CreateUserFlight(
    ${USER_FLIGHT_VARIABLES}
  ) {
    createUserFlight(
      ${USER_FLIGHT_ARGUMENTS}
    ) {
      userFlight { id }
      errors { path, message }
      flightOptions {
        id
        flightawareId
        departureAt
        origin { city, icao, iata }
        destination { city, icao, iata }
      }
      flightSetup {
        departureDate
        tailNumber
        flightNumber
        airline { id }
      }
      cannotLookupReason
    }
  }
`;

export const UPLOAD_USER_FLIGHTS = gql`
  mutation UploadUserFlights(
    $file: Upload!
    $sourceType: String!
    $updateData: Boolean
  ) {
    uploadUserFlights(
      file: $file
      sourceType: $sourceType
      updateData: $updateData
    ) {
      result
      message
    }
  }
`;

export const UPDATE_USER_FLIGHT = gql`
  mutation UpdateUserFlight(
    $id: ID!
    $originId: ID
    $destinationId: ID
    $airlineId: ID
    $operatorId: ID
    $planeTypeId: ID
    $tailNumber: String
    $departureAt: ISO8601DateTime
    $departureTerminal: String
    $departureGate: String
    $arrivalAt: ISO8601DateTime
    $arrivalTerminal: String
    $arrivalGate: String
    $seatNumber: String
    $flightNumber: Int
    $cabin: String
    $bagCount: Int
    $bagClaim: String
    $exitRow: Boolean
    $bulkhead: Boolean
    $seatType: String
    $reason: String
    $notes: String
    $ticketNumber: String
    $pnr: String
    $fareClass: String
    $fare: Decimal
    $taxes: Decimal
    $qualifyingMilesEarned: Decimal
    $milesEarned: Decimal
    $spendEarned: Decimal
    $segmentsEarned: Decimal
  ) {
    updateUserFlight(
      id: $id
      originId: $originId
      destinationId: $destinationId
      airlineId: $airlineId
      operatorId: $operatorId
      planeTypeId: $planeTypeId
      tailNumber: $tailNumber
      departureAt: $departureAt
      departureTerminal: $departureTerminal
      departureGate: $departureGate
      arrivalAt: $arrivalAt
      arrivalTerminal: $arrivalTerminal
      arrivalGate: $arrivalGate
      seatNumber: $seatNumber
      flightNumber: $flightNumber
      cabin: $cabin
      bagCount: $bagCount
      bagClaim: $bagClaim
      exitRow: $exitRow
      bulkhead: $bulkhead
      seatType: $seatType
      reason: $reason
      notes: $notes
      ticketNumber: $ticketNumber
      pnr: $pnr
      fareClass: $fareClass
      fare: $fare
      taxes: $taxes
      qualifyingMilesEarned: $qualifyingMilesEarned
      milesEarned: $milesEarned
      spendEarned: $spendEarned
      segmentsEarned: $segmentsEarned
    ) {
      userFlight { id }
      errors { path, message }
    }
  }
`;

export const AIRLINES = gql`
   query {
    airlines {
      id
      iata
      icao
      name
    }
  }
`;

export const USER_FLIGHT_IMPORT_COLUMNS = gql`
  query NoUserLogUserFlightImportColumns {
    userFlightImportColumns {
      key
      label
      required
    }
  }
`;

export const FILTER_SELECTED = gql`
  query NoUserLogFilterSelected(
    $airline: String
    $operator: String
    $airport: String
    $pairAirport: String
    $aircraft: String
  ) {
    filterSelected(
      airline: $airline
      operator: $operator
      airport: $airport
      pairAirport: $pairAirport
      aircraft: $aircraft
    ) {
      airline { id, name, icao, iata }
      operator { id, name, icao, iata }
      airport { id, name, icao, iata }
      pairAirport { id, name, icao, iata }
      aircraft { id, name, icao, iata }
    }
  }
`;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import ClientContext from '../../contexts/ClientContext';

const Menu = () => {
  const { currentUser } = useContext(ClientContext);

  return (    
        <>  
          <div class="footer-top">
            <div class="row">
              <div class="col-lg-4">
                <a href="/privacypolicy">Privacy Policy</a>
              </div>
              <div class="col-lg-4">
               
              </div>
              <div class="col-lg-4">
                
              </div>
            </div>
          </div>

          <div class="footer-bottom">
            <i class="far fa-copyright"></i> 2023 Travel Social, LLC
          </div>
        </>

  );
};

export default Menu;

import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';

import DataState from '../../DataState';
import ElasticSearch from '../../../elements/ElasticSearch';

import { FILTER_SELECTED } from './queries';

const Row = styled.div`
`;

const AutocompleteFilter = props => {
  const { type, name, control, getValue, defaultSelected } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <ElasticSearch
          type={type}
          onChange={selected => {
            field.onChange(selected && getValue(selected));
          }}
          defaultSelected={defaultSelected}
        />
      )}
    />
  )
};

const getAirlineValue = airline => (airline.icao?.toLowerCase() || airline.id);
const getAirportValue = airport => (airport.icao?.toLowerCase() || airport.id);
const getPlaneTypeValue = planeType => (
  planeType.icao?.toLowerCase() || planeType.id
);

const Filter = ({ username, filters, setFilters }) => {
  const [open, setOpen] = useState(false);

  const { register, handleSubmit, control, watch } = useForm({
    defaultValues: filters
  });

  const airline = useRef({});
  airline.current = watch('airline');

  const airport = useRef({});
  airport.current = watch('airport');

  const navigate = useNavigate();

  const { data, ...queryStatus } = useQuery(FILTER_SELECTED, {
    variables: filters
  });

  const updateUrl = (path, other) => {
    const searchParams = []

    Object.keys(other).forEach(key => {
      if (other[key]) {
        searchParams.push(`${key}=${other[key]}`);
      }
    });

    const fullPath = `/journeys/${username}/flights/${path}`;

    if (searchParams.length) {
      navigate(`${fullPath}?${searchParams.join('&')}`);
    } else {
      navigate(fullPath);
    }
  };

  const handleFilter = data => {
    if (!data.airline) {
      data.flightNumber = '';
    }

    if (!data.airport) {
      data.pairAirport = null;
    }

    if (data.airline) {
      if (data.flightNumber) {
        const { airline, flightNumber, ...other } = data;
        updateUrl(`airline/${airline}/flight-number/${flightNumber}`, other);
      } else {
        const { airline, ...other } = data;
        updateUrl(`airline/${airline}`, other);
      }
    } else if (data.operator) {
      const { operator, ...other } = data;
      updateUrl(`operator/${data.operator}`, other);
    } else if (data.airport) {
      if (data.pairAirport) {
        const { airport, pairAirport, ...other } = data;
        updateUrl(`city-pair/${data.airport}/${data.pairAirport}`, other);
      } else {
        const { airport, ...other } = data;
        updateUrl(`airport/${data.airport}`, other);
      }
    } else if (data.aircraft) {
      const { aircraft, ...other } = data;
      updateUrl(`aircraft/${data.aircraft}`, other);
    } else if (data.tail) {
      const { tail, ...other } = data;
      updateUrl(`tail/${data.tail}`, other);
    } else if (data.year) {
      const { year, ...other } = data;
      updateUrl(`year/${data.year}`, other);
    } else {
      updateUrl('', data);
    }

    setFilters({...data});
    setOpen(false);
  };

  return (
    <>
      <button onClick={() => setOpen(true)} className="submit-button" style={{width: "100%"}}>
        Filter Flights by Airline, Year, and More!
      </button>

      <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
        <DataState.Wrapper data={data} {...queryStatus}>
          <div style={{marginLeft: 16, marginRight: 16, marginTop: 6}}>
            <form onSubmit={handleSubmit(handleFilter)} className="ts-form">
              <Row>
                Airline
                <AutocompleteFilter
                  name="airline"
                  control={control}
                  type="Airline"
                  getValue={getAirlineValue}
                  defaultSelected={data?.filterSelected.airline}
                />
              </Row>

              {airline.current && (
                <Row>
                  Flight Number
                  <input {...register('flightNumber')} />
                </Row>
              )}

              <Row>
                Operator
                <AutocompleteFilter
                  name="operator"
                  control={control}
                  type="Airline"
                  getValue={getAirlineValue}
                  defaultSelected={data?.filterSelected.operator}
                />
              </Row>

              <Row>
                Airport
                <AutocompleteFilter
                  name="airport"
                  control={control}
                  type="Airport"
                  getValue={getAirportValue}
                  defaultSelected={data?.filterSelected.airport}
                />
              </Row>

              {airport.current && (
                <Row>
                  Add a second airport to filter for a specific route
                  <AutocompleteFilter
                    name="pairAirport"
                    control={control}
                    type="Airport"
                    getValue={getAirportValue}
                    defaultSelected={data?.filterSelected.pairAirport}
                  />
                </Row>
              )}

              <Row>
                Aircraft Type
                <AutocompleteFilter
                  name="aircraft"
                  control={control}
                  type="PlaneType"
                  getValue={getPlaneTypeValue}
                  defaultSelected={data?.filterSelected.aircraft}
                />
              </Row>

              <Row>
                Tail Number
                <input {...register('tail')} />
              </Row>

              <Row>
                Year
                <input
                  type="number"
                  {...register('year')}
                  min="1900"
                  max={new Date().getFullYear().toFixed()}
                />
              </Row>

              <Row style={{marginTop: 10}}>
                <input
                  value="Apply Filters"
                  type="submit"
                  id="submit-button"
                />
              </Row>
            </form>
          </div>
        </DataState.Wrapper>
      </Dialog>
    </>
  );
};

export default Filter;

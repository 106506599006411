import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';

import DataState from '../DataState';

import Form from './form';
import { USER_FILE, UPDATE_USER_FILE } from './queries';
import useUserFilePath from './path';

const EditUserFile = ({ resourceType }) => {
  const { resourceId, id } = useParams();
  const userFilePath = useUserFilePath();

  const [submitting, setSubmitting] = useState();
  const {
    register, control, handleSubmit, reset, watch, formState: { errors }
  } = useForm();

  const { data } = useQuery(USER_FILE, { variables: { id } });

  const [updateUserFile, { error }] = useMutation(UPDATE_USER_FILE, {
    onCompleted: () => {
      window.location.href = userFilePath({ resourceType, resourceId });
    }
  });

  useEffect(() => {
    if (data?.userFile) {
      reset({
        id: data.userFile.id,
        name: data.userFile.name,
        type: data.userFile.type,
        visibility: data.userFile.visibility,
      });
    }
  }, [data]);

  const onSubmit = data => {
    setSubmitting(true);
    updateUserFile({ variables: data });
  };

  return (
    <Container>
      <h2>Update File</h2>
      <center>
        <div className="form-container">
          <DataState.Wrapper loading={submitting}>
            <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
              <Form
                register={register}
                control={control}
                resourceType={resourceType}
                errors={errors}
                watch={watch}
                userFile={data?.userFile}
              />

              <input value="Update File" type="submit" id="submit-button" />
            </form>
          </DataState.Wrapper>
        </div>
      </center>
    </Container>
  );
};

export default EditUserFile;

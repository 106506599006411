import { gql } from '@apollo/client';

import {
  WITH_COUNT_VARIABLES,
  WITH_COUNT_ARGUMENTS
} from '../../queries/withCount';

const AIRPORT_CORE_FIELDS = gql`
  fragment AirportCoreFields on Airport {
    id
    name
    icao
    iata
    latitude
    longitude
    slug
  }
`;

export const AIRPORTS = gql`
  ${AIRPORT_CORE_FIELDS}
  query AirportsWithCount(
    ${WITH_COUNT_VARIABLES}
  ) {
    airportsWithCount(
      ${WITH_COUNT_ARGUMENTS}
    ) {
      entries {
        ...AirportCoreFields
      }
      count
    }
  }
`;

export const AIRPORT = gql`
  ${AIRPORT_CORE_FIELDS}
  query Airport($slug: String!) {
    airportBySlug(slug: $slug) {
      ...AirportCoreFields
      marker
      infoBox
    }
  }
`;
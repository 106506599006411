import axios from 'axios';

import config from '../config';

export const rest = args => {
  if (args.path && !args.url) {
    args.url = `${config.api.baseUri}/${args.path}`;
  }

  if (!args.method) { args.method = 'get' };

  args.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/xlsx',
    ...args.headers
  }

  const storedCredentials = localStorage.getItem('ts-credentials');

  if (storedCredentials) {
    const credentials = JSON.parse(storedCredentials);

    args.headers = {
      ...args.headers,
      ...credentials,
      'access-token': credentials.accessToken,
    }
  }

  return axios(args);
};

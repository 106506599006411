import React, { useEffect, useContext, useState } from 'react';
import { View, TextInput } from 'react-native';
import { useForm } from 'react-hook-form';

import ClientContext from '../../contexts/ClientContext';
import config from '../../config';
import Input from '../../elements/inputs';



const typeOptions = [
  {
    value: 'comment',
    label: 'General Comment',
  },
  {
    value: 'bug',
    label: 'Bug Report',
  },
  {
    value: 'feature',
    label: 'Feature Request',
  },
];

const Ticket = () => {
  const [submitting, setSubmitting] = useState();
  const { currentUser } = useContext(ClientContext);
  var referrer = document.referrer;
  const { control, handleSubmit, getValues, watch, reset, formState: { errors } } = useForm({
    defaultValues: {
      type: 'comment',
      name: currentUser?.displayName,
      email: currentUser?.email,
      subject: '',
      problem: '',
      expectation: '',
      supplied_url: referrer
    }
  });

  useEffect(() => {
    reset({name: currentUser?.displayName, email: currentUser?.email})
  }, [currentUser]);

  const onSubmit = data => {
    const { type, ...values } = data;
    if ('bug' !== type) {
      values.subject = typeOptions.find(({ value }) => value === type).label +
        ': ' + values.subject;
    }

    values.url = referrer;

    fetch('https://preflighttech.com/api/1/trouble_tickets', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: config.pti.token,
      },
      body: JSON.stringify(values)
    }).then((response) => {
      response.json().then(data => {
        console.log(data);
        setSubmitting(`Thank you for submitting your feedback. <i>${data.app_name}${data.id}</i>`)
      })
    });

    setSubmitting('Submitting...');
  };

  if (submitting) {
    return (
      <View>
        <div>{submitting}</div>
      </View>
    );
  }

  const labels = {
    subject: "In just a few words, what isn't working correctly?",
    expectation: 'What were you expecting to happen?',
  };

  watch('type');
  const type = getValues('type');

  if (type === 'feature') {
    labels.subject = 'In just a few words, what would you like to see added?';
    labels.expectation = "Are there any details of what you'd expect to see?";
  } else if (type === 'comment') {
    labels.subject = 'Subject';
    labels.expectation = 'Your comments';
  }

  return (
    <View>
      <center>
        <div class="form-container">
          <Input.Picker
            label="What type of feedback do you have?"
            name="type"
            options={typeOptions}
            control={control}
            errors={errors}
          />
          <Input.Text
            label="Your Name"
            name="name"
            rules={{required: true}}
            control={control}
            errors={errors}
          />
          <Input.Text
            label="Email"
            name="email"
            rules={{required: true}}
            control={control}
            errors={errors}
          />
          <Input.Text
            label={labels.subject}
            name="subject"
            rules={{required: true}}
            control={control}
            errors={errors}
          />
          {'bug' === type && (
            <Input.Text
              label="Please describe in detail what went wrong"
              name="problem"
              control={control}
            />
          )}
          <Input.Text
            label={labels.expectation}
            name="expectation"
            control={control}
          />

          <input value="Send Feedback" type="submit" id="submit-button" onClick={handleSubmit(onSubmit)} />
        </div>
      </center>
    </View>
  );
};

export default Ticket;

import { gql } from '@apollo/client';

import {
  WITH_COUNT_VARIABLES,
  WITH_COUNT_ARGUMENTS
} from '../../queries/withCount';

const AIRLINE_CORE_FIELDS = gql`
  fragment AirlineCoreFields on Airline {
    id
    name
    url
    logoUri
    icao
    iata
    callsign
    country
    about
    marker
    slug
  }
`;

export const AIRLINES = gql`
  ${AIRLINE_CORE_FIELDS}
  query AirlinesWithCount(
    ${WITH_COUNT_VARIABLES}
  ) {
    airlinesWithCount(
      ${WITH_COUNT_ARGUMENTS}
    ) {
      entries {
        ...AirlineCoreFields
      }
      count
    }
  }
`;

export const AIRLINE = gql`
  ${AIRLINE_CORE_FIELDS}
  query Airline($slug: String!) {
    airlineBySlug(slug: $slug) {
      ...AirlineCoreFields
      loyaltyProgram { id }
      slug
      marker
      infoBox
      airlineAirports { 
        id,
        hub, 
        focusCity, 
        latitude, 
        longitude, 
        marker, 
        infoBox, 
        airport { name, slug, icao, iata, city, country }
      }
    }
  }
`;

const AIRLINE_VARIABLES = `
  $name: String!
  $icao: String
  $iata: String
  $callsign: String
  $country: String
  $about: String
  $loyaltyProgramId: ID
  $url: String
  $marker: Upload
  $approvedLogo: Upload
`;

const AIRLINE_ARGUMENTS = `
  name: $name
  icao: $icao
  iata: $iata
  callsign: $callsign
  country: $country
  about: $about
  loyaltyProgramId: $loyaltyProgramId
  url: $url
  marker: $marker
  approvedLogo: $approvedLogo
`;

export const CREATE_AIRLINE = gql`
  ${AIRLINE_CORE_FIELDS}
  mutation CreateAirline(
    ${AIRLINE_VARIABLES}
  ) {
    createAirline(
      ${AIRLINE_ARGUMENTS}
    ) {
      airline {
        ...AirlineCoreFields
      }
      errors { path, message }
    }
  }
`;

export const UPDATE_AIRLINE = gql`
  ${AIRLINE_CORE_FIELDS}
  mutation UpdateAirline(
    $id: ID!
    ${AIRLINE_VARIABLES}
  ) {
    updateAirline(
      id: $id
      ${AIRLINE_ARGUMENTS}
    ) {
      airline {
        ...AirlineCoreFields
      }
      errors { path, message }
    }
  }
`;


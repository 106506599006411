import React from 'react';

const ActionLink = ({ handleClick, children }) => {
  const handleClickWithPrevents = e => {
    e.preventDefault();
    e.stopPropagation();
    handleClick(e);
  }

  return (
    <a href="#" onClick={handleClickWithPrevents}>{children}</a>
  );
};

export default ActionLink;

import React from 'react';
import { Row, Col } from 'react-bootstrap';

import UserErrors from '../../../elements/UserErrors';

const LoyaltyProgramForm = ({ register, control, userErrors }) => {
  return (
    <>
      {userErrors && <UserErrors userErrors={userErrors} />}

      <Row>
        <Col>
          <div id="general-information">
            Type of Loyalty Program:
            <select
              type="select"
              {...register('programType')}
            >
              <option>Hotel</option>
              <option>Airline</option>
              <option>Rental Car</option>
              <option>Combo</option>
            </select>
          </div>
          Name
          <input
            type="text"
            {...register('name')}
          />
          Company Name
          <input
            type="text"
            {...register('companyName')}
          />
          URL
          <input
            type="text"
            {...register('url')}
          />
          Logo
          <input
            type="file"
            {...register('logo')}
          />
        </Col>
      </Row>
    </>
  );
};

export default LoyaltyProgramForm;

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import FlightOptions from './flightOptions';
import DataState from '../../DataState';
import ClientContext from '../../../contexts/ClientContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Alert from 'react-bootstrap/Alert';
import FileDownload from 'js-file-download';

import { rest } from '../../../utils/rest';

import { USER_FLIGHT_IMPORT_COLUMNS, UPLOAD_USER_FLIGHTS } from './queries';

const UploadFlights = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState();
  const [mfd, setMfd] = useState(true);
  const [custom, setCustom] = useState(false);
  const [response, setResponse] = useState();
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      updateData: 'true'
    }
  });
  const { currentUser } = useContext(ClientContext);

  const { data: importColumnsData } = useQuery(USER_FLIGHT_IMPORT_COLUMNS);

  const [uploadUserFlights, { error }] = useMutation(UPLOAD_USER_FLIGHTS, {
    onCompleted: data => {
      if ('premium-required' === data.uploadUserFlights.result) {
        navigate('/profile/premium/upload-flights')
      } else {
        setResponse(data.uploadUserFlights)
      }
    }
  });

  if (currentUser.username != username) {
    console.log("USERNAME CHECK")
    navigate(`/journeys/${currentUser.username}/flights/upload`);
    return false;
  }

  const onSubmit = data => {
    data.departureAt = DateTime.fromISO(data.departureAt).toISO();
    data.arrivalAt = DateTime.fromISO(data.arrivalAt).toISO();
    if (data.flightNumber) {
      data.flightNumber = parseInt(data.flightNumber);
    }
    if (data.airlineId) {
      data.airlineId = parseInt(data.airlineId);
    }

    data.updateData = 'true' === data.updateData ? true : false;
    uploadUserFlights({ variables: data });
    setSubmitting(true);
  };

  const handleTypeChange = event => {
    if (event.target.value == "mfd") {
      setMfd(true);
      setCustom(false);
    } else {
      setMfd(false);
      setCustom(true);
    }
  };

  const handleDownload = () => {
    rest({
      path: 'exports/flights',
      responseType: 'blob',
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }).then(result => {
      FileDownload(result.data, 'Travel.Social Flights Import.xlsx');
    });
  }

  if (response) {
    return (
      <Container>
        <Alert variant="success">
          {response.message}
        </Alert>
      </Container>
    );
  }

  let premiumFeatureNote = 'this is a premium feature';
  if ('annual' === currentUser?.activeSubscription?.type) {
    premiumFeatureNote =
      'Included in your premium subscription at no additional charge!';
  } else if ('monthly' === currentUser?.activeSubscription?.type) {
    premiumFeatureNote = 'Included for up to 50 flights processed a month ' +
      'with your monthly premium subscription'
  }

  return (
    <Container>
      <h2>Upload Your List of Flights</h2>
      <center>
        <div className="form-container">
          <DataState.Wrapper loading={submitting}>
            <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
              <Row>
                <Col>
                  <input
                    type="radio"
                    className="ts-form"
                    id="update-data-true"
                    {...register('updateData')}
                    value="true"
                  />
                  <label className="ts-form" htmlFor="update-data-true">
                    Update as many of my flights as you can with the actual
                    flight path, departure and arrival times, tail numbers,
                    and more!<br />
                    ({premiumFeatureNote})
                  </label>

                  <input
                    type="radio"
                    className="ts-form"
                    id="update-data-false"
                    {...register('updateData')}
                    value="false"
                  />
                  <label className="ts-form" htmlFor="update-data-false">
                    Import my flights just as I have them recorded,
                    don't try to update anything.<br />(Free!)
                  </label>
                </Col>
              </Row>
              <Row style={{marginTop: 10}}>
                <Col>
                  <div id="general-information">
                    Where is your list of flights from?
                    <select
                      type="select"
                      {...register('sourceType', {
                        onChange: (e) => {handleTypeChange(e);}
                      })}
                    >
                      <option value="mfd">My FlightRadar24 (formerly MyFlightDiary)</option>
                      <option value="custom">Custom Upload</option>
                    </select>
                  </div>
                </Col>
              </Row>

              { mfd && (
                <Row id="flight-number-options">
                  <Col>
                    My FlightRadar24 can be accessed by visiting <a href="https://my.flightradar24.com/settings/export" target="_new">https://my.flightradar24.com/</a>. 
                    If you have an account with data stored, navigate to "Settings" in the top right of the page, then click on "Export" from
                    the left side.  Finally, click the green "Download CSV" button.  Use that export to upload below:
                  </Col>
                </Row>
              )}

              { custom && (
                <Row id="tail-number-options">
                  <Col>
                    If you've just kept a list of your own flights, all you need to do is create a CSV, XLS, or XLSX file with your 
                    data in the following column format:<br />

                    <button
                      onClick={handleDownload}
                      type="button"
                      style={{marginBottom: 20}}
                    >
                      Download Import Template
                    </button>

                    <ol>
                      {importColumnsData?.userFlightImportColumns.map(column => (
                        <li key={column.key}>
                          {column.label}
                          {column.required && (
                            <span
                              style={{
                                color: 'red',
                                fontStyle: 'italic',
                                marginLeft: 10,
                              }}
                            >
                              Required
                            </span>
                          )}
                        </li>
                      ))}
                    </ol>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  File: <i>File must be XLS, XLSX, or CSV</i>
                  <input
                      type="file"
                      accept=".xls,.xlsx,.csv"
                      {...register('file')}
                  / >
                </Col>
              </Row>

              <input value="Upload Flights" type="submit" id="submit-button"  />
            </form>
          </DataState.Wrapper>
        </div>
      </center>
    </Container>
  );
};

export default UploadFlights;

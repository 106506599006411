import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { DateTime } from 'luxon';
import { setTime } from '../../../utils/dateHelpers';
import DataState from '../../DataState';
import ClientContext from '../../../contexts/ClientContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Alert from 'react-bootstrap/Alert';
import { CREATE_USER_FLIGHT, AIRLINES } from './queries';



const FlightOptions = (props) => {
  const navigate = useNavigate();
  const options = props.data.flightOptions;
  const [submitting, setSubmitting] = useState(false);
  const [showFlightOptions, setShowFlightOptions] = useState(null);
  const [userErrors, setUserErrors] = useState(null);
  const { register, handleSubmit, control, watch } = useForm();
  const { currentUser } = useContext(ClientContext);

  const [createUserFlight, { error }] = useMutation(CREATE_USER_FLIGHT, {
    onCompleted: data => {
      if (data.createUserFlight.userFlight) {
        navigate(`/journeys/${currentUser.username}/flights/${data.createUserFlight.userFlight.id}/edit`);
      } else if (data.createUserFlight.flightOptions.length > 0) {
        setShowFlightOptions(data.createUserFlight.flightOptions);
        setSubmitting(false);
      } else {
        setUserErrors(data.createUserFlight.errors);
        setSubmitting(false);
      }
    }
  });

  const onSubmit = data => {
    data.airlineId = parseInt(props.data.flightSetup.airline.id);
    data.departureDate = props.data.flightSetup.departureDate;
    data.tailNumber = props.data.flightSetup.tailNumber;
    data.flightNumber = props.data.flightSetup.flightNumber;
    createUserFlight({ variables: data });
    setSubmitting(true);
  };

  if (showFlightOptions) return <FlightOptions options={showFlightOptions} />;

  return (
    <Container>
    { userErrors != null && (
        <>
          <Alert key="danger" variant="danger">
          {userErrors[0].message}
        </Alert>
        </>
      )}
      <div class="form-container">
        <DataState.Wrapper loading={submitting}>
          <h2>We Found {options.length} Matching Flights</h2>
          Please select the correct flight option:
          <div id="general-information">
            <form onSubmit={handleSubmit(onSubmit)} className="ts-form">
              {
                options.map(option =>  {
                  return (
                    <>
                      <input type="radio" class="ts-form" id={`pick_flight_id_${option.id}`} value={option.flightawareId} {...register('flightawareFlightId')} />
                      <label class="ts-form" for={`pick_flight_id_${option.id}`}>
                        {option.origin.city} ({option.origin.iata}) to {option.destination.city} ({option.destination.iata})<br />
                        Departed At: 
                        {option.departureAt && 
                          <>&nbsp;{setTime(option.departureAt)}</>
                        }
                        {!option.departureAt && 
                          <>&nbsp;Not Yet Departed</>
                        }
                      </label>
                    </>
                  )
                })
              }
              <br /><br />
              <input value="Continue With Selected Flight" type="submit" id="submit-button"  />
            </form>
          </div>
        </DataState.Wrapper>
      </div>
    </Container>
  );
};

export default FlightOptions;

import React, { useEffect, useState } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import styled from 'styled-components';

export const SEARCH = gql`
  query Search($term: String!, $type: String!) {
    search(term: $term, type: $type) {
      ... on Hotel {
        id
        name
        location
      }
      ... on Airline {
        id
        name
        icao
        iata
      }
      ... on Airport {
        id
        name
        icao
        iata
      }
      ... on PlaneType {
        id
        name
        icao
        iata
      }
    }
  }
`;

const Clear = styled.div`
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  color: gray;
  cursort: pointer;
`;

const Container = styled.div`
  &:hover {
    position: relative;

    ${Clear} {
      display: block;
    }
  }
`;

const Results = styled.div`
  position: absolute;
  top: 36px;
  left: 2px;
  width: calc(100% - 4px);
  background-color: #fffffff2;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  max-height: 500px;
  overflow: scroll;
`;

const ResultItem = styled.div`
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;

  &:hover {
    background-color: lightgray;
  }
`;

const label = resource => {
  if (!resource) { return ''; }

  let content = resource.__typename;

  if (['Airline', 'Airport', 'PlaneType'].includes(resource.__typename)) {
    content = resource.name;
    if (resource.icao || resource.iata) {
      const codes = [resource.icao, resource.iata].filter(code => code);
      content = content + ' (' + codes.join('/') + ')'
    }
  } else if ('Hotel' === resource.__typename) {
    content = resource.name;
    if (resource.location) {
      content = `${content} - ${resource.location}`;
    }
  }

  return content;
};

const Result = ({ result, onChoose }) => {
  return (
    <ResultItem onClick={() => onChoose(result)}>
      {label(result)}
    </ResultItem>
  );
};

const ElasticSearch = ({ onChange, defaultSelected, type }) => {
  const [searching, setSearching] = useState('');
  const [selected, setSelected] = useState(defaultSelected);
  const [term, setTerm] = useState(label(defaultSelected));
  const [getResults, { data }] = useLazyQuery(SEARCH);

  useEffect(() => {
    if (searching && term && term.length > 2) {
      getResults({ variables: { term, type }});
    }
  }, [searching, term, type, getResults]);

  const handleChoose = value => {
    setSearching(false);
    setTerm(label(value));
    setSelected(value);
    onChange(value);
  };

  const handleKeyUp = e => {
    if ('Escape' === e.key) {
      handleChoose(selected);
    } else if (!searching) {
      setSearching(true);
    }
  };

  return (
    <Container style={searching ? { position: 'relative' } : {}}>
      <input
        type="text"
        value={term}
        onChange={e => setTerm(e.target.value)}
        onKeyUp={handleKeyUp}
      />
      {term && (
        <Clear onClick={() => handleChoose(null)}>
          <i class="fas fa-xmark"></i>
        </Clear>
      )}
      {data && searching && term.length > 2 && data.search.length > 0 && (
        <Results>
          {data.search.map(result => (
            <Result
              key={`${result.__typename}-${result.id}`}
              result={result}
              onChoose={handleChoose}
            />
          ))}
        </Results>
      )}
    </Container>
  );
};

export default ElasticSearch;
